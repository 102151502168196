import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core';

import useStyles from './style'

const Load = ({load}) => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Load 