export default function (state = [], action) {
  switch (action.type) {
    case 'ATRIBUIR_PACIENTE': 
      return action.payload

    case 'SELECIONAR_PACIENTE_PACIENTELIST':
      const paciente = action.payload.list.filter(paciente => paciente._id === action.payload.id ? paciente : '') 
      return paciente[0]
      
    case 'EDITAR_PACIENTE':
      return action.payload

    case 'EDITAR_CONSULTA':
      return action.payload
    
    case 'DELETAR_CONSULTA':
      return action.payload
    
    case 'INSERIR_PRESCRICAO':
      return action.payload

    case 'EDITAR_PRESCRICAO':
      return action.payload
    
    case 'DELETAR_PRESCRICAO':
      return action.payload

    case 'INSERIR_SOLICITACAO':
      return action.payload

    case 'EDITAR_SOLICITACAO':
      return action.payload
    
    case 'DELETAR_SOLICITACAO':
      return action.payload
      
    case 'INSERIR_FICHA':
      return action.payload
    
    case 'DELETAR_FICHA':
      return action.payload

    case 'INSERIR_EXAME':
      return action.payload
    
    case 'DELETAR_EXAME':
      return action.payload

    case 'FINALIZAR_CONSULTA':
      return action.payload

    case 'SAIR_VISUALIZACAO':
      return action.payload
    
    default:
    return state
  }
}