import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  Accordion, 
  AccordionActions, 
  AccordionDetails, 
  AccordionSummary, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Divider, 
  Grid, 
  GridList, 
  GridListTile, 
  GridListTileBar, 
  IconButton, 
  Paper, 
  Snackbar, 
  TextField, 
  Typography } from '@material-ui/core';
import { Delete, ExpandMore, Pageview } from '@material-ui/icons';

import CanvasDraw from 'react-canvas-draw';
import { format, parseISO } from 'date-fns';

import useStyles from './style'
import { editarPrescricao, inserirPrescricao, deletarPrescricao } from '../../store/actions/Paciente.actions';
import { loadCSS } from 'fg-loadcss';
import ReceituarioEdit from '../DialogEdit/receituarioEdit'


const Prescricao = ({pen}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const user = JSON.parse(localStorage.getItem('Usuario'))
  const paciente = useSelector(state => state.Paciente)

  const [dados, setDados] = React.useState({titulo: '', dados: ''})
  const [pesquisar, setPesquisar] = React.useState(false);
  const [remedio, setRemedio] = React.useState();
  const [snack, setSnack] = React.useState({open: false});
  const [open, setOpen] = React.useState(false)
  const [deletar, setDeletar] = React.useState('')

  const [imprimir, setImprimir] = React.useState({medico: user.nome, crm: user.crm, paciente: paciente.nome, endereco: user.endereco});

  const [dimensions, setDimensions] = React.useState({width: window.innerWidth , height: window.innerHeight})

  const handleResize = (e) => {
    setDimensions({width: window.innerWidth, height: window.innerHeight})
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  })

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const handleDelete = (data) => {
    dispatch(deletarPrescricao(paciente._id, deletar)); setSnack({open: false})
  }

  const handleSalvar = async (campo, canvas) => {
    if(dados.tipo === 'salvar') {
      if(canvas.current.value) {
        const keyboard = canvas.current.value 
        dispatch(inserirPrescricao(paciente._id, keyboard))
        setOpen(false)
      } else if (canvas.current.value === undefined) {
        const pencil = canvas.current.getSaveData()
        dispatch(inserirPrescricao(paciente._id, pencil))
        setOpen(false)
      } else {
        setOpen(false)
      }
    } else {
      if(canvas.current.value) {
        const keyboard = canvas.current.value 
        dispatch(editarPrescricao(dados.id, keyboard))
        setOpen(false)
      } else if (canvas.current.value === undefined) {
        const pencil = canvas.current.getSaveData()
        dispatch(editarPrescricao(dados.id, pencil))
        setOpen(false)
      } else {
        setOpen(false)
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    
    setRemedio(value);
  }

  const busca = (buscar) => {
    switch (buscar) {
      case 'medicamentosa':
        window.open(`http://www.consultaremedios.com.br/${remedio.toLowerCase()}/bula#interacao-medicamentosa`, '_blank');
        break;
      case 'alimentar':
        window.open(`http://www.consultaremedios.com.br/${remedio.toLowerCase()}/bula#interacao-alimenticia`, '_blank');
        break;
      default:
        window.open(`http://www.consultaremedios.com.br/${remedio.toLowerCase()}/bula`, '_blank');
    }
  }

  return (
    <>
      {/* Exibição das prescrições anteriores  */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Prescrever / Prescrições Anteriores</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item lg={12} sm={12}>
            <GridList  className={classes.GridList} cols={2}>
            {(paciente.prescricao || []).map((prescricao, index) => (
              <GridListTile key={index}>
              {(prescricao.texto === undefined || prescricao.texto === null ) ? ('') : (
                (prescricao.texto.indexOf("lines") === -1) ? (
                <TextField
                  name="queixa"
                  variant="outlined"
                  value={prescricao.texto}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                <CanvasDraw
                  catenaryColor="#fff"
                  brushRadius={1}
                  canvasWidth={200}
                  canvasHeight={130}
                  hideGrid={true}
                  lazyRadius={1}
                  style={{border: '1px solid #000'}}
                  immediateLoading={true}
                  saveData={prescricao.texto}
                  disabled
                />
              ))}
                <GridListTileBar
                    title={format(parseISO(prescricao.data), 'dd/MM/yyy')}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <>
                      <IconButton color='primary' aria-label="star" onClick={() => {
                        setDados({open: false, titulo: 'Prescrição', dados: prescricao.texto, id: prescricao._id, data: prescricao.data, tipo: 'editar', paciente: paciente.nome})
                        setImprimir({...imprimir, texto: prescricao.texto, open: true}); 
                        setOpen(true)
                      }}>
                        <Pageview />
                      </IconButton>
                      <IconButton color='secondary' aria-label="star" onClick={() => {
                        setDeletar({ texto: prescricao.texto, _id: prescricao._id, data: prescricao.data })
                        setSnack({open: true, message: 'Tem certeza que deseja excluir?'})
                      }}>
                        <Delete />
                      </IconButton>
                    </>
                  }
                />
            </GridListTile>
            ))}
            </GridList>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" color='primary' onClick={() => {setDados({titulo: 'Prescrição', dados: '', tipo:'salvar', paciente: paciente.nome}); setOpen(true)}}>Prescrever</Button>
        </AccordionActions>
      </Accordion>

       {/* Componente de edição da consulta */}
       <ReceituarioEdit
        open={open} 
        setOpen={setOpen} 
        handleSalvar={handleSalvar} 
        dados={dados} 
        pen={pen} 
        dimensions={dimensions} 
        imprimir={imprimir} 
        setPesquisar={setPesquisar}
      />

      {/* Dialog de pesquisa de remédios  */}
      <Dialog
      fullWidth
      maxWidth='sm'
      open={pesquisar}
      >
      <DialogTitle id="max-width-dialog-title">Pesquisar Medicamentos</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Escreve o nome do medicamento"
                    name="medicamento"
                    onChange={handleChange}
                  /> 
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size='small' color="primary" onClick={() => {busca('bula')}}>
          Ver Bula
        </Button>
        <Button size='small' color="primary" onClick={() => {busca('medicamentosa')}}>
          Ver interação medicamentosa
        </Button>
        <Button size='small' color="primary" onClick={() => {busca('alimentar')}}>
          Ver interação alimentar
        </Button>
        <Button size='small' color="primary" onClick={() => {setPesquisar(false)}}>
          Sair
        </Button>
      </DialogActions>
    </Dialog>



    {/* Snackbar confirmar deleção */}
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={snack.open}
      message={snack.message}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={() => {setSnack({open: false})}}>
            não
          </Button>
          <Button size="small" color="primary" onClick={ handleDelete }>
            sim
          </Button>
        </React.Fragment>
      }
    />
    </>
  )
}

export default Prescricao