import React from 'react';
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { People, MenuBook, Today, Person } from '@material-ui/icons';

export default function medicoMenu() {
  return (
    <div>
      <ListItem button component={Link} to='/medico/perfil' >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary="Perfil"/>
      </ListItem> 
      <ListItem button component={Link} to='/painel' >
        <ListItemIcon>
          <Today />
        </ListItemIcon>
        <ListItemText primary="Consultas" />
      </ListItem>
      <ListItem button component={Link} to='/medico/paciente' >
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Pacientes" />
      </ListItem>
      <ListItem button component={Link} to='/medico/cbhpm'>
        <ListItemIcon>
          <MenuBook />
        </ListItemIcon>
        <ListItemText primary="CBHPM" />
      </ListItem>
      <ListItem button component={Link} to='/medico/cid'>
        <ListItemIcon>
          <MenuBook />
        </ListItemIcon>
        <ListItemText primary="CID-10" />
      </ListItem>
    </div>
  );
}