import api from "../../services/api"
import { ordenaPacientes } from '../../utils/ordenaPacientes'

// Obtêm no banco dados dos pacientes a partir do ID do médico 
export const obterPacientes = (id) => async (dispatch) => {
  const pacientes = await api
    .get(`/paciente/${id}`)
    .then(res => res.data.paciente)
    .catch(err => err.message)
  dispatch({
    type: 'OBTER_PACIENTES_ASYNC',
    payload: ordenaPacientes(pacientes)
  })
}

// Insere paciente no banco 
export const inserirPaciente = (dados) => async (dispatch) =>  {
  await api
    .post("/paciente", dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)
  dispatch({
    type: 'INSERIR_PACIENTE',
    payload: '',
  })
}

// Exclui paciente do banco
export const excluirPaciente = (id) => async (dispatch) => {
  await api
    .delete(`/paciente/deletar/${id}`)
    .then(res => res.data.paciente)
    .catch(err => err.message)
  dispatch({
    type: 'EXCLUIR_PACIENTE',
    payload: ''
  })
}
