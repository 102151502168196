import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  // carrosel
  // container: {
  //   overflowX: 'auto',
  // },

  // card: {
  //   marginBottom: '15px',
  //   minWidth: '300px',
  // },

  // conteudo: {
  //   maxHeight: '200px',
  //   overflow: 'hidden',
  // },
  fab: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  fabPen: {
    position: 'absolute',
    top: theme.spacing(13),
    right: theme.spacing(8),
  },
  fabKey: {
    position: 'absolute',
    top: theme.spacing(13),
    right: theme.spacing(2),
  },
  gridRoot: {
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  pos: {
    marginBottom: 12,
  },
}));

export default useStyles;