import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { 
  AppBar, 
  Button, 
  Fab, 
  Paper, 
  Snackbar, 
  TextField, 
  Toolbar
} from '@material-ui/core';

import useStyles from './style';
import ViewConsulta from '../../../../components/Consulta/ViewConsulta'
import Prescricao from '../../../../components/Prescricao'
import Solicitacao from '../../../../components/Solicitacao'
import Exames from '../../../../components/Exame'
import Fichas from '../../../../components/Ficha'
import { Edit, Keyboard, Save } from '@material-ui/icons';
import { sairVisualizacao } from '../../../../store/actions/Paciente.actions';
import LoadPage from '../../../../components/Load'
import InputMask from 'react-input-mask'
import CadPaciente from '../../../../components/CadPaciente'
import { editarPaciente } from '../../../../store/actions/Paciente.actions';
import { excluirPaciente, obterPacientes } from '../../../../store/actions/ListaPacientes.actions'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        ProntuDATA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Consulta = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const { _id } = JSON.parse(localStorage.getItem('Usuario'));

  const load = useSelector(state => state.Api)
  const paciente = useSelector(state => state.Paciente)

  const [dimensions, setDimensions] = React.useState({width: window.innerWidth, height: window.innerHeight})
  const [click, setClick] = React.useState({ pen: 'primary', key: 'default'});
  const [pen, setPen] = React.useState(false);
  const [snack, setSnack] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [data, setData] = React.useState({});

  const handleResize = (e) => {
    setDimensions({width: window.innerWidth, height: window.innerHeight});
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  },[])

  const handleAtualizaPaciente = () => {
    setData({
      nome: paciente.nome,
      email: paciente.email,
      nascimento: paciente.nascimento,
      telefone: paciente.telefone,
      celular: paciente.celular,
      plano: paciente.plano,
      endereco: paciente.endereco
    });
    setDialog(true);
  };

  const handleSalvar = async () => {
    console.log(data)
    dispatch(editarPaciente(paciente._id, data))
    setDialog(false);
    setData({})
  }; 

  const handleExcluir = async () => {
    setSnack(false)
    dispatch(excluirPaciente(paciente._id))
    dispatch(obterPacientes(_id))
    props.history.goBack()
  };

  const handleVoltar = () => {
    dispatch(sairVisualizacao(paciente))
    props.history.goBack()
  }

  return (
    <div className={classes.root}>
      <LoadPage load={load} />
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <Button
            aria-controls="simple-menu" 
            aria-haspopup="true"
            size='small'
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<Save />}
            onClick={handleVoltar}
          >
            Voltar
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                  Ficha do Paciente 
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="nome"
                      name="nome"
                      label="Nome"
                      disabled
                      value={paciente.nome}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="99/99/9999" 
                      value={paciente.nascimento}
                      required disableUnderline 
                      disabled={true}
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        disabled
                        value={paciente.nascimento}
                        name="nascimento" 
                        label='Nascimento'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="email"
                      disabled
                      value={paciente.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask
                      mask="(99) 99999-9999" 
                      value={paciente.celular}
                      disabled={true}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        disabled
                        value={paciente.celular}
                        name="celular" 
                        label='Celular'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  <InputMask 
                      mask="(99) 9999-9999" 
                      value={paciente.telefone}
                      disabled={true}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        disabled
                        value={paciente.telefone}
                        name="telefone" 
                        label='Telefone'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      id="plano"
                      name="plano"
                      label="Plano de Saúde"
                      disabled
                      value={paciente.plano}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      id="endereco"
                      name="endereco"
                      label="Endereço Completo"
                      disabled
                      value={paciente.endereco}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAtualizaPaciente()}
                    >
                    Editar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setSnack(true)}
                    >
                    Excluir
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              {/* Botoões caneta e teclado */}
              <Fab color={click.key} size="small" aria-label="teclado" className={classes.fabKey} onClick={() => {
                setPen(false)
                setClick({
                  pen: 'primary',
                  key: 'default',
                })
              }}>
                <Keyboard />
              </Fab>      
              <Fab size="small" color={click.pen} aria-label="add" className={classes.fabPen} onClick={() => {
                setPen(true)
                setClick({
                  pen: 'default',
                  key: 'primary',
                })
              }}>
                <Edit/>
              </Fab>
            </Grid>
            <Grid item lg={6} sm={12} >
            <Typography variant="h6" >Arquivos</Typography>
              <Exames />
              <Fichas />
            </Grid>
            <Grid item sm={12} lg={6}>
              <Typography variant="h6" >Prescrições e Solicitações</Typography>
              <Prescricao pen={pen}   />
              <Solicitacao pen={pen}  />
            </Grid>
          </Grid>
          <br />
          <Grid item lg={12} sm={12}>
            {(paciente.consulta[0] === undefined) ? ('') : (<ViewConsulta pen={pen} dimensions={dimensions} />)}
          </Grid>

          {/* Dialog para cadastro e atualização de novos Pacientes */}
          <CadPaciente medico={_id} setData={setData} data={data} setDialog={setDialog} dialog={dialog} handleSalvar={handleSalvar}/>

          {/* Snackbar confirmar deleção */}
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={snack}
            message='Deseja realmente excluir este paciente?'
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={() => {setSnack(false)}}>
                  não
                </Button>
                <Button size="small" color="primary" onClick={() => {handleExcluir()}}>
                  sim
                </Button>
              </React.Fragment>
            }
          />

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Consulta);
