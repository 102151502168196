import { Dialog, DialogContent, DialogActions, Button, DialogTitle, Grid, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import InputMask from 'react-input-mask'
import { Close, Save } from '@material-ui/icons';

const CadPaciente = ({medico, setData, data, setDialog, dialog, handleSalvar}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const value = event.target.value;
    setData({
      ...data,
      [event.target.name]: value,
      medico: medico,
    });
  };

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
      fullWidth
      maxWidth='lg'
      open={dialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Cadastrar Paciente</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="nome"
                      label="Nome"
                      fullWidth
                      autoComplete="given-name"
                      defaultValue={data.nome}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      mask="99/99/9999" 
                      onChange={handleChange}
                      defaultValue={data.nascimento}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        name="nascimento" 
                        label='Nascimento'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      name="email"
                      label="E-mail"
                      fullWidth
                      defaultValue={data.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask
                      mask="(99) 99999-9999" 
                      onChange={handleChange}
                      defaultValue={data.celular}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        name="celular" 
                        label='Celular'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask 
                      mask="(99) 9999-9999" 
                      onChange={handleChange}
                      defaultValue={data.telefone}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        name="telefone" 
                        label='Telefone'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="plano"
                      label="Plano de Saúde"
                      fullWidth
                      defaultValue={data.plano}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="endereco"
                      label="Endereço Completo"
                      fullWidth
                      defaultValue={data.endereco}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Close />
          Cancelar
        </Button>
        <Button onClick={handleSalvar} color="primary">
          <Save />
          Salvar
        </Button>
      </DialogActions>
    </Dialog>

    
    </React.Fragment>
  )
}

export default withRouter(CadPaciente);