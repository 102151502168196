import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import useStyles from './style';
import { AppBar, Button, Dialog, Fab, GridList, GridListTile, GridListTileBar, IconButton, Slide, TextField, Toolbar } from '@material-ui/core';
import { withRouter, Prompt } from 'react-router-dom';
import { Assignment, Close, Edit, Keyboard, Pageview, Save } from '@material-ui/icons';
// import CanvasDraw from 'react-canvas-draw';
import { loadCSS } from 'fg-loadcss';
import { useSelector, useDispatch } from 'react-redux';
import { finalizarConsulta } from '../../../../store/actions/Paciente.actions';
import { atualizarConsulta, limparConsulta } from '../../../../store/actions/Consulta.actions';
import Solicitacao from '../../../../components/Solicitacao';
import Prescricao from '../../../../components/Prescricao';
import Exames from '../../../../components/Exame';
import Fichas from '../../../../components/Ficha';
import DialogEdit from '../../../../components/DialogEdit'
import api from '../../../../services/api';
import LoadPage from '../../../../components/Load'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        ProntuDATA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Consulta = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const load = useSelector(state => state.Api)

  const paciente = useSelector(state => state.Paciente)
  const consulta = useSelector(state => state.Consulta)
  const agenda = props.location.state.agenda;
  
  const [pen, setPen] = React.useState(false);
  const [dados, setDados] = React.useState({titulo: '', dados: ''})
  const [click, setClick] = React.useState({ pen: 'primary', key: 'default'});
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState({open: false});
  const [salvo, setSalvo] = React.useState(false)
  const [dimensions, setDimensions] = React.useState({width: window.innerWidth , height: window.innerHeight})

  const handleResize = (e) => {
    setDimensions({width: window.innerWidth, height: window.innerHeight})
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  },[])

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);
  
  const handleSalvar = (campo, canvas) => {
    if(canvas.current.value) {
      const keyboard = canvas.current.value 
      dispatch(atualizarConsulta(campo, keyboard))
      setOpen(false)
    } else if (canvas.current.value === undefined) {
      const pencil = canvas.current.getSaveData()
      dispatch(atualizarConsulta(campo, pencil))
      setOpen(false)
    } else {
      setOpen(false)
    }
  }

  const handleFinalizarConsulta = async () => {
    await api.delete(`/agenda/cancelar/${agenda}`).then(res => {
      dispatch(finalizarConsulta(paciente, consulta, agenda))
      dispatch(limparConsulta())
      setSalvo(true)
      props.history.goBack()
    }).catch(err => {
      console.log(err.response);
    })
  }

  return (
    <div className={classes.root}>
      <LoadPage load={load} />
      <Prompt
        message={() => {
          if (salvo === false){
            return `Você tem certeza que deseja sair desta consulta sem salvar?`
          } 
        }}
      />
      
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <Button
            size='small'
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<Save />}
            onClick={handleFinalizarConsulta}
          >
            Finalizar Consulta
          </Button>
          <Button
            size='small'
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<Assignment />}
            onClick={() => {setShow({open: true, click: 'fichas'})}}
          >
            Fichas Anteriores / Exames Anteriores
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {/* Dados do paciente  */}
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12}>
              <Typography variant="h6" gutterBottom>
                Paciente: {paciente.nome} - Nascimento: {paciente.nascimento}
              </Typography>
              <Typography variant="h6" gutterBottom>
                1º Consulta
              </Typography>
              {/* Botões caneta e teclado */}
              <Fab color={click.key} size="small" aria-label="teclado" className={classes.fabKey} onClick={() => {
                setPen(false)
                setClick({
                  pen: 'primary',
                  key: 'default',
                })
              }}>
                <Keyboard />
              </Fab>      
              <Fab size="small" color={click.pen} aria-label="add" className={classes.fabPen} onClick={() => {
                setPen(true)
                setClick({
                  pen: 'default',
                  key: 'primary',
                })
              }}>
                <Edit/>
              </Fab>
            </Grid>
            {/* Inserção da consulta  */}
            <Grid item sm={12} >
            <Typography variant="caption">Escolha a parte da ficha que deseja preencher!</Typography>    
            <div className={classes.gridRoot}>
              <GridList className={classes.gridList} cols={3.5}>
                <GridListTile>
                {(consulta.queixa === null || consulta.queixa === undefined) ? (
                  <TextField
                    name="queixa"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.queixa.indexOf("lines") === -1) ? (
                  <TextField
                    name="queixa"
                    variant="outlined"
                    value={consulta.queixa}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  /////////////////////////////////////////////////////////////////////////////////////// 
                  //    Mudei para textfield pois o canvasdraw não permite deslizar usando os dedos.   //
                  //    Pensar em outra forma de exibir os dados.                                      //
                  ///////////////////////////////////////////////////////////////////////////////////////
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.queixa}
                  //   disabled
                  // />
                  <TextField
                    name="queixa"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Queixa Principal"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({titulo: '1º Consulta - Queixa Principal', dados: consulta.queixa, campo: 'queixa', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.queixa === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.doenca === null || consulta.doenca === undefined ) ? (
                  <TextField
                    name="doenca"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.doenca.indexOf("lines") === -1) ? (
                  <TextField
                    name="doenca"
                    variant="outlined"
                    value={consulta.doenca}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.doenca}
                  //   disabled
                  // />
                  <TextField
                    name="doenca"
                    variant="outlined"
                    value={consulta.doenca}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="História da Doença Atual"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({titulo: '1º Consulta - História da Doença Atual', dados: consulta.doenca, campo: 'doenca', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.doenca === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.historico === null || consulta.historico === undefined) ? (
                  <TextField
                    name="historico"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.historico.indexOf("lines") === -1) ? (
                  <TextField
                    name="historico"
                    variant="outlined"
                    value={consulta.historico}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.historico}
                  //   disabled
                  // />
                  <TextField
                    name="historico"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="História Patológica Pregressa"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: '1º Consulta - História Patológica Pregressa', dados: consulta.historico, campo: 'historico', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.historico === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.medicamentos === null || consulta.medicamentos === undefined) ? (
                  <TextField
                    name="medicamentos"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.medicamentos.indexOf("lines") === -1) ? (
                  <TextField
                    name="medicamentos"
                    variant="outlined"
                    value={consulta.medicamentos}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.medicamentos}
                  //   disabled
                  // />
                  <TextField
                    name="medicamentos"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Medicamentos em Uso"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" colro='primary' onClick={() => {
                        setDados({open: true, titulo: '1º Consulta - Medicamentos em Uso', dados: consulta.medicamentos, campo: 'medicamentos', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.medicamentos === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.exame === null || consulta.exame === undefined) ? (
                  <TextField
                    name="exame"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.exame.indexOf("lines") === -1) ? (
                  <TextField
                    name="exame"
                    variant="outlined"
                    value={consulta.exame}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.exame}
                  //   disabled
                  // />
                  <TextField
                    name="exame"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Exame Físico"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: '1º Consulta - Exame Físico', dados: consulta.exame, campo: 'exame', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.exame === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.hipotese === null || consulta.hipotese === undefined) ? (
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.hipotese.indexOf("lines") === -1) ? (
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    value={consulta.hipotese}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.hipotese}
                  //   disabled
                  // />
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Hipótese Diagnóstica"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: '1º Consulta - Hipótese Diagnóstica', dados: consulta.hipotese, campo: 'hipotese', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.hipoteses === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.conduta === null || consulta.conduta === undefined) ? (
                  <TextField
                    name="conduta"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.conduta.indexOf("lines") === -1) ? (
                  <TextField
                    name="conduta"
                    variant="outlined"
                    value={consulta.conduta}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth.width}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.conduta}
                  //   disabled
                  // />
                  <TextField
                    name="conduta"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Conduta"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: '1º Consulta - Conduta', dados: consulta.conduta, campo: 'conduta', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.conduta === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
              </GridList>
            </div>
          </Grid>
          {/* Prescrição e solicitação */}
            <Grid item sm={12}>
              <Typography variant="h6" >Prescrições e Solicitações</Typography>
              <Prescricao pen={pen} />
              <Solicitacao pen={pen} />
            </Grid>
          </Grid>

          {/* Componente de edição da consulta */}
          <DialogEdit open={open} setOpen={setOpen} handleSalvar={handleSalvar} dados={dados} pen={pen} dimensions={dimensions} />
          
          
          {/* Dialog Fichas e exames */}
          <Dialog maxWidth='md' fullWidth open={show.open} onClose={() => {}} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={() => {setShow({open: false})}} aria-label="close">
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid item sm={12} className={classes.gridModal}>
              <Exames />
              <Fichas />
            </Grid>
          </Dialog>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Consulta);
