export default function (state = [], action) {
  switch (action.type) {
    case 'OBTER_PACIENTES_ASYNC':
      return action.payload

    case 'EXCLUIR_PACIENTE':
      return action.payload

    case 'INSERIR_PACIENTE':
      return action.payload

    default:
      return state
  }
}