import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  table: {
    minWidth: 600,
  },

  cadButton: {
    width: '30ch',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),  
  },
  actionBtn: {
    marginLeft: theme.spacing(1),  
  }
}));

export default useStyles;