import React from 'react';
import AgendaSecretaria from './AgendaSecretaria'
import AgendaMedico from './AgendaMedico';

export default function Agenda(props) {
  const user = JSON.parse(localStorage.getItem('Usuario'));

  if (user.role === 'medico') {
    return <AgendaMedico />
  } else {
    return <AgendaSecretaria />
  };
}