import React from 'react'
import { TextField } from '@material-ui/core'

import logo from '../../assets/logo-receituario.png'
import CanvasDraw from 'react-canvas-draw'

// Width: 210mm 794px, height: 297mm 1122px
export class PrintComponent extends React.PureComponent {
  render() {
    return (
      <div ref={this.props.printRef}> 
        <div style={{position: 'absolute', paddingTop: '50px', paddingLeft: '50px', fontSize: '25px'}}><img src={logo} alt='Logo Uromed' width={150} height={100} /></div>
        <div style={{position: 'absolute', paddingTop: '60px', paddingLeft: '550px', fontSize: '25px'}}>{this.props.imprimir.medico}</div>
        <div style={{position: 'absolute', paddingTop: '90px', paddingLeft: '560px', fontSize: '20px'}}><b>CRM:</b> {this.props.imprimir.crm}</div>
        <div style={{position: 'absolute', paddingTop: '200px', paddingLeft: '50px', width: '740px', height: '700px', fontSize: '30px'}}>
        {(this.props.dados.dados !== '') ? (
          (this.props.dados.dados.indexOf('lines') === -1) ? (
            <TextField
              type="text"
              id='keyboard'
              variant='standard'
              defaultValue={this.props.dados.dados}
              rows={35}
              rowsMax={35}
              multiline
              fullWidth
              disabled
            /> 
          ) : (
            <CanvasDraw
              canvasWidth={740}
              canvasHeight={700}
              hideGrid={true}
              lazyRadius={1}
              immediateLoading={true}
              saveData={this.props.dados.dados}
              disabled
            />
          )
        ) : null}
        </div>
        {/* <div style={{position: 'absolute', paddingTop: '950px', paddingLeft: '50px', fontSize: '15px'}}> Data: ____/____/20____</div> */}
        {/* <div style={{position: 'absolute', paddingTop: '950px', paddingLeft: '350px', fontSize: '15px'}}>___________________________________________________</div> */}
        {/* <div style={{position: 'absolute', paddingTop: '980px', paddingLeft: '470px', fontSize: '15px'}}>Assinatura do Médico</div> */}
        <div style={{position: 'absolute',paddingTop: '1030px', paddingLeft: '250px', fontSize: '15px'}}>Rua Alencar Lima, 35  -  Centro  -  Petrópolis / RJ</div>
      </div>
    )
  }
}

export default PrintComponent