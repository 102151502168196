import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  fab: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  pageBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fabAdd: {
    marginBottom: theme.spacing(1),
    alignContent: 'center',
  },
  topButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  printComponent: {
    position: 'absolute',
    display: 'none',
    height: 0,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  canva: {
    marginLeft: theme.spacing(6),
  }
}));

export default useStyles;