import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { 
  TableContainer, 
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@material-ui/core';
import { Person, Visibility } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { obterPacientes, inserirPaciente } from '../../store/actions/ListaPacientes.actions';
import { atribuirPaciente } from '../../store/actions/Paciente.actions';
import LoadPage from '../Load'
import CadPaciente from '../CadPaciente'

const PacienteListMedico = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const load = useSelector(state => state.Api)

  const paciente = useSelector(state => state.ListaPacientes);
  const { _id } = JSON.parse(localStorage.getItem('Usuario'));

  const [data, setData] = React.useState({});
  const [dialog, setDialog] = useState(false);
  

  useEffect(() => {
    loadPaciente()
    // eslint-disable-next-line
  }, []);

  const loadPaciente = async () => {
    dispatch(obterPacientes(_id))
  }

  const handleVisualizar = (paciente) => {
    dispatch(atribuirPaciente(paciente))
    props.history.push({
      pathname: '/medico/paciente/visualizar',
    });
  };

  const handleSalvar = async () => {
    dispatch(inserirPaciente(data))
    setDialog(false);
    setData({})
    loadPaciente();
  };

  return (
    <React.Fragment>
    <LoadPage load={load} />
    <Typography variant="h6" gutterBottom>
      Pacientes
    </Typography>
    <Button
      variant="contained"
      color="default"
      size='small'
      className={classes.cadButton}
      onClick={() => setDialog(true)}
      startIcon={<Person />}
    >
      Cadastrar Paciente
    </Button>
    
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Paciente</TableCell>
            <TableCell align="right">Endereço</TableCell>
            <TableCell align="right">Telefone</TableCell>
            <TableCell align="right">Celular</TableCell>
            <TableCell align="right">Plano</TableCell>
            <TableCell align="right">Última Consulta</TableCell>
            <TableCell align="right">Visualizar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(paciente || []).map(row => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.nome}
              </TableCell>
              <TableCell align="right">{row.endereco}</TableCell>
              <TableCell align="right">{row.telefone}</TableCell>
              <TableCell align="right">{row.celular}</TableCell>
              <TableCell align="right">{row.plano}</TableCell>
              <TableCell align="right">{row.ultimaConsulta && format(parseISO(row.ultimaConsulta), 'dd/MM/yyyy')}</TableCell>
              <TableCell align="right">
              {/* <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.actionBtn}
                startIcon={<Edit />}
                onClick={() => handleClick('atualizar', row)}
              >
                Editar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                className={classes.actionBtn}
                startIcon={<Delete />}
                onClick={() => {setDel(row._id); setSnack(true)}}
              >
                Deletar
              </Button> */}
              <Button
                variant="contained"
                color="default"
                size="small"
                className={classes.actionBtn}
                startIcon={<Visibility />}
                onClick={() => handleVisualizar(row)}
              >
                Visualizar
              </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    {/* Dialog para cadastro e atualização de novos Pacientes */}
    <CadPaciente medico={_id} setData={setData} data={data} setDialog={setDialog} dialog={dialog} handleSalvar={handleSalvar}/>
    
    </React.Fragment>
  );
}

export default withRouter(PacienteListMedico);