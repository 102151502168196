import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  Accordion, 
  AccordionActions, 
  AccordionDetails, 
  AccordionSummary, 
  Button, 
  Divider, 
  Grid, 
  GridList, 
  GridListTile, 
  GridListTileBar, 
  IconButton, 
  Snackbar, 
  TextField, 
  Typography } from '@material-ui/core';
import { Delete, ExpandMore, Pageview } from '@material-ui/icons';
import CanvasDraw from 'react-canvas-draw';
import { format, parseISO } from 'date-fns';
import useStyles from './style'
import { editarSolicitacao, inserirSolicitacao, deletarSolicitacao } from '../../store/actions/Paciente.actions';
import { loadCSS } from 'fg-loadcss';
import ReceituarioEdit from '../DialogEdit/receituarioEdit'

const Solicitacao = ({pen}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const user = JSON.parse(localStorage.getItem('Usuario'))
  const paciente = useSelector(state => state.Paciente)

  const [dados, setDados] = React.useState({open: false, titulo: '', dados: ''})
  const [imprimir, setImprimir] = React.useState({medico: user.nome, crm: user.crm, paciente: paciente.nome, endereco: user.endereco});
  const [deletar, setDeletar] = React.useState('')
  const [snack, setSnack] = React.useState({open: false});
  const [open, setOpen] = React.useState(false)

  const [dimensions, setDimensions] = React.useState({width: window.innerWidth , height: window.innerHeight})

  const handleResize = (e) => {
    setDimensions({width: window.innerWidth, height: window.innerHeight})
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  })

  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);


  const handleDelete = (data) => {
    dispatch(deletarSolicitacao(paciente._id, deletar)); setSnack({open: false})
  }

  const handleSalvar = async (campo, canvas) => {
    if(dados.tipo === 'salvar') {
      if(canvas.current.value) {
        const keyboard = canvas.current.value 
        dispatch(inserirSolicitacao(paciente._id, keyboard))
        setOpen(false)
      } else if (canvas.current.value === undefined) {
        const pencil = canvas.current.getSaveData()
        dispatch(inserirSolicitacao(paciente._id, pencil))
        setOpen(false)
      } else {
        setOpen(false)
      }
    } else {
      if(canvas.current.value) {
        const keyboard = canvas.current.value 
        dispatch(editarSolicitacao(dados.id, keyboard))
        setOpen(false)
      } else if (canvas.current.value === undefined) {
        const pencil = canvas.current.getSaveData()
        dispatch(editarSolicitacao(dados.id, pencil))
        setOpen(false)
      } else {
        setOpen(false)
      }
    }
  };

  return (
    <>
      {/* Exibição das solicitações anteriores */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Solicitar / Solicitações Anteriores</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item lg={12} sm={12}>
            <GridList  className={classes.GridList} cols={2}>
            {(paciente.solicitacao || []).map((solicitacao, index) => (
              <GridListTile key={index}>
              {(solicitacao.texto === undefined || solicitacao.texto === null ) ? ('') : (
                (solicitacao.texto.indexOf("lines") === -1) ? (
                <TextField
                  name="queixa"
                  variant="outlined"
                  value={solicitacao.texto}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                <CanvasDraw
                  catenaryColor="#fff"
                  brushRadius={1}
                  canvasWidth={200}
                  canvasHeight={130}
                  hideGrid={true}
                  lazyRadius={1}
                  style={{border: '1px solid #000'}}
                  immediateLoading={true}
                  saveData={solicitacao.texto}
                  disabled
                />
              ))}
                <GridListTileBar
                    title={format(parseISO(solicitacao.data), 'dd/MM/yyy')}
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <>
                      <IconButton color='primary' aria-label="star" onClick={() => {
                        setDados({open: false, titulo: 'Solicitação', dados: solicitacao.texto, id: solicitacao._id, data: solicitacao.data, tipo: 'editar', paciente: paciente.nome})
                        setImprimir({...imprimir, texto: solicitacao.texto, open: true}); 
                        setOpen(true);
                      }}>
                        <Pageview />
                      </IconButton>
                      <IconButton color='secondary' aria-label="star" onClick={() => {
                        setDeletar({texto: solicitacao.texto, _id: solicitacao._id, data: solicitacao.data});
                        setSnack({open: true, message: 'Tem certeza que deseja excluir?'})
                      }}>
                        <Delete />
                      </IconButton>
                    </>
                  }
                />
              </GridListTile>
            ))} 
            </GridList>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button color='primary' size="small" onClick={() => {setDados({open: false, titulo: 'Solicitação', tipo: 'salvar', dados: '', paciente: paciente.nome}); setOpen(true)}}>Solicitar</Button>
        </AccordionActions>
      </Accordion>

      {/* Componente de edição da consulta */}
      <ReceituarioEdit open={open} setOpen={setOpen} handleSalvar={handleSalvar} dados={dados} pen={pen} dimensions={dimensions} imprimir={imprimir} />
      
      {/* Snackbar confirmar deleção */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={snack.open}
        message={snack.message}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => { setDados({open: false, dados: '', titulo: ''}); setSnack({open: false})}}>
              não
            </Button>
            <Button size="small" color="primary" onClick={ handleDelete }>
              sim
            </Button>
          </React.Fragment>
        }
      />
    </>
  )
}

export default Solicitacao