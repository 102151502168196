export const novaConsulta = (titulo, id) => {
  return { 
    type: 'NOVA_CONSULTA',
    payload: {
      pacienteID: id,
      titulo: titulo,
      queixa: '',
      doenca: '',
      historico: '',
      medicamentos: '',
      exame: '',
      hipotese: '',
      conduta: '',
      consulta: '',
    }
  }
}

export const atualizarConsulta = (campo, dados) => {
  return {
    type: 'ATUALIZAR_CONSULTA',
    payload: {
      campo,
      dados
    }
  }
}

export const limparConsulta = () => {
  return {
    type: 'LIMPAR_CONSULTA',
  }
}

