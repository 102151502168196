import { Badge, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import React from 'react';

export default function secretariaMenuSecondary(medico) {
  return (
    <div>
      <ListSubheader >Chat com Médicos</ListSubheader>
      {medico.map((list) => (
        <ListItem button key={list._id}>
          <ListItemIcon>
            <Badge badgeContent={2} color="secondary" >
              <AccountCircle color="primary" />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={list.nome} />
        </ListItem>
      ))}
    </div>
  );
};