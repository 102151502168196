import React, { useState } from 'react'
import { 
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button, 
  Divider, 
  Grid, 
  IconButton, 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemSecondaryAction, 
  ListItemText, 
  Snackbar, 
  Typography
} from '@material-ui/core';
import { Delete, ExpandMore, FileCopy, Folder } from '@material-ui/icons';

import { format, parseISO } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { deletarFicha, inserirFicha } from '../../store/actions/Paciente.actions'

import Envio from '../Arquivos/envio' 
import useStyles from './style';

const Fichas = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const paciente = useSelector(state => state.Paciente)
  const [arquivo, setArquivo] = useState(false);
  const [deletar, setDeletar] = useState('')
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [data, setData] = useState();

  const handleSalvarFicha = async () => {
    dispatch(inserirFicha(paciente._id, data))
    setArquivo(false);
  };
  
  return (
    <>
      {/* Exibição das fichas já enviadas */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Fichas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item lg={12} sm={12} className={classes.gridRoot}>
            <div className={classes.demo}>
              <List>
              {(paciente.fichas || []).map((ficha, index) => (
                <ListItem key={index}>
                  <ListItemAvatar onClick={() => {
                    window.open(`https://api.prontudata.com.br/files/${ficha.img}`, '_blank');
                  }}>
                    <Avatar>
                      <Folder />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ficha.numero}
                    secondary={format(parseISO(ficha.data), 'dd/MM/yyy')}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => {
                      setDeletar(ficha);
                      setConfirmDelete(true);
                    }}>
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              </List>
            </div>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <IconButton size="small" onClick={() => {
                setArquivo(true);
              }}><FileCopy /></IconButton>
        </AccordionActions>
      </Accordion>
     
      {/* Dialog salvar ficha  */}
      <Envio
        paciente={paciente.nome}
        titulo="ficha" 
        data={data} 
        setData={setData} 
        arquivo={arquivo}
        setArquivo={setArquivo}
        handleSalvar={handleSalvarFicha}
      />

      {/* Snackbar confirmar deleção */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={confirmDelete}
        message="Tem certeza que deseja excluir?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => { setConfirmDelete(false); }}>
              não
            </Button>
            <Button size="small" color="primary" onClick={() => {dispatch(deletarFicha(paciente._id, deletar)); setConfirmDelete(false)}}>
              sim
            </Button>
          </React.Fragment>
        }
      />

    </>
  )
}

export default Fichas;
