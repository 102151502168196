export const TOKEN_KEY = "token";
export const USER = "Usuario"
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = data => {
  const token = data.token;
  const user = data.usuario;

  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER, JSON.stringify(user));
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER);
};
