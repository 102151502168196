import React, { useEffect, useState } from 'react';
import { 
  Accordion, 
  AccordionActions, 
  AccordionDetails, 
  AccordionSummary, 
  Button, 
  Divider, 
  Grid, 
  GridList, 
  GridListTile, 
  GridListTileBar, 
  IconButton, 
  TextField, 
  Typography,
  Snackbar 
} from '@material-ui/core';
import { ExpandMore, Delete, Pageview } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
// import CanvasDraw from 'react-canvas-draw';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import { loadCSS } from 'fg-loadcss';
import { useSelector, useDispatch } from 'react-redux'
import { editarConsulta, deletarConsulta } from '../../store/actions/Paciente.actions';
import DialogEdit from '../DialogEdit';
import CanvasDraw from 'react-canvas-draw';

const View = ({ pen, dimensions }) => {
  const dispatch = useDispatch()
  const paciente = useSelector(state => state.Paciente)
  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const [dados, setDados] = useState({titulo: '', dados: ''})
  const [deletar, setDeletar] = useState({open: false, id: '', campo: '', consulta: ''})

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);


  const handleSalvar = async (campo, canvas) => {
    if(canvas.current.value) {
      const keyboard = canvas.current.value 
      dispatch(editarConsulta(dados.consulta, campo, keyboard, dados.id))
      setOpen(false)
    } else if (canvas.current.value === undefined) {
      const pencil = canvas.current.getSaveData()
      dispatch(editarConsulta(dados.consulta, campo, pencil, dados.id))
      setOpen(false)
    } else {
      setOpen(false)
    }
  };

  const handleDeletar = () => {
    if (deletar.campo !== 'total') {
      dispatch(editarConsulta(deletar.consulta, deletar.campo, '', deletar.id))
      setDeletar({open: false, id: '', campo: '', consulta: ''})
    } else {
      dispatch(deletarConsulta(deletar.id, deletar.consulta))
      setDeletar({open: false, id: '', campo: '', consulta: ''})
    }
  }

  return (
    <React.Fragment>
    {/* Exibição dos dados de retorno  */}
    {(paciente.consulta || []).map((row, index) => (      
      (row.titulo === 'Retorno') ? (
      <Accordion key={index}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
      
      <IconButton color='secondary' size='small' onClick={() => {setDeletar({open: true, id: paciente._id, campo: 'total', consulta: row})}}>
        <Delete/>
      </IconButton>
      <Typography className={classes.heading}>{" - " + row.titulo + " - " + format(parseISO(row.data), 'dd/MM/yyyy')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item lg={12} sm={12} onClick={() => {
          setDados({titulo: row.titulo + ' - Consulta', consulta: row, dados: row.consulta, campo: 'consulta', id: row._id, paciente: paciente.nome});
        }}>
        {(row.consulta === undefined || row.consulta === null ) ? (
          <TextField
            name="consulta"
            variant="outlined"
            rows={5}
            rowsMax={20}
            fullWidth
            multiline
            disabled
          /> 
        ) : (
        (row.consulta.indexOf("lines") === -1) ? (
          <TextField
            name="consulta"
            variant="outlined"
            value={row.consulta}
            rows={5}
            rowsMax={20}
            fullWidth
            multiline
            disabled
          /> 
        ) : (
          /////////////////////////////////////////////////////////////////////////////////////// 
          //    Mudei para textfield pois o canvasdraw não permite deslizar usando os dedos.   //
          //    Pensar em outra forma de exibir os dados.                                      //
          ///////////////////////////////////////////////////////////////////////////////////////
          // <CanvasDraw
          //   catenaryColor="#fff"
          //   brushRadius={1}
          //   canvasWidth={gridWidth}
          //   canvasHeight={300}
          //   hideGrid={true}
          //   lazyRadius={1}
          //   style={{border: '1px solid #000'}}
          //   immediateLoading={true}
          //  saveData={row.consulta}
          //   disabled
          // />
          <TextField
            name="consulta"
            variant="outlined"
            rows={5}
            rowsMax={20}
            fullWidth
            multiline
            disabled
          /> 
        ))}
        </Grid>
      </AccordionDetails>
      <Divider />
        <AccordionActions>
          <IconButton size="small" color='primary' onClick={() => {
            setDados({titulo: row.titulo + ' - Consulta', consulta: row, dados: row.consulta, campo: 'consulta', id: row._id, paciente: paciente.nome});
            setOpen(true)
          }}><Pageview /></IconButton>
        </AccordionActions>
    </Accordion>
    ) : (
      <Accordion key={index}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
      <IconButton color='secondary' size='small' onClick={() => {setDeletar({open: true, id: paciente._id, campo: 'total', consulta: row})}}>
        <Delete/>
      </IconButton>
      <Typography className={classes.heading}>{" - " + row.titulo + " - " + format(parseISO(row.data), 'dd/MM/yyyy')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item lg={12} sm={12} >
          <div className={classes.gridRoot}>
            <GridList className={classes.gridList} cols={1.5}>
              <GridListTile>
              {(row.queixa === undefined || row.queixa === null ) ? (
                <TextField
                  name="queixa"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.queixa.indexOf("lines") === -1) ? (
                <TextField
                  name="queixa"
                  variant="outlined"
                  value={row.queixa}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                <CanvasDraw
                  catenaryColor="#fff"
                  brushRadius={1}
                  canvasWidth={300}
                  canvasHeight={130}
                  hideGrid={true}
                  lazyRadius={1}
                  style={{border: '1px solid #000'}}
                  immediateLoading={true}
                  saveData={row.queixa}
                  disabled
                />
                // <TextField
                //   name="queixa"
                //   variant="outlined"
                //   rows={5}
                //   rowsMax={20}
                //   fullWidth
                //   multiline
                //   disabled
                // /> 
              ))}
                <GridListTileBar
                    title="Queixa Principal"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                      <IconButton color='primary' aria-label="star" onClick={() => {
                        setDados({ titulo: row.titulo + ' - Queixa Principal', consulta: row, dados: row.queixa, campo: 'queixa', id: row._id, paciente: paciente.nome })
                        setOpen(true)
                      }}>
                        <Pageview/>
                      </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.doenca === undefined || row.doenca === null) ? (
                <TextField
                  name="doenca"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.doenca.indexOf("lines") === -1) ? (
                <TextField
                  name="doenca"
                  variant="outlined"
                  value={row.doenca}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.doenca}
                //   disabled
                // />
                <TextField
                  name="doenca"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="História da Doença Atual"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({ titulo: row.titulo + ' - História da Doença Atual', consulta: row, dados: row.doenca, campo: 'doenca', id: row._id, paciente: paciente.nome })
                        setOpen(true)
                      }}>
                        <Pageview/>
                      </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.historico === undefined || row.historico === null) ? (
                <TextField
                  name="historico"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.historico.indexOf("lines") === -1) ? (
                <TextField
                  name="historico"
                  variant="outlined"
                  value={row.historico}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.historico}
                //   disabled
                // />
                <TextField
                  name="historico"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="História Patológica Pregressa"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({ titulo: row.titulo + ' - História Patológica Pregressa', consulta: row, dados: row.historico, campo: 'historico', id: row._id, paciente: paciente.nome })
                      setOpen(true)
                    }}>
                      <Pageview/>
                    </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.medicamentos === undefined || row.medicamentos === null) ? (
                <TextField
                  name="medicamentos"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.medicamentos.indexOf("lines") === -1) ? (
                <TextField
                  name="medicamentos"
                  variant="outlined"
                  value={row.medicamentos}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.medicamentos}
                //   disabled
                // />
                <TextField
                  name="medicamentos"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="Medicamentos em Uso"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({ titulo: row.titulo + ' - Medicamentos Em Uso', consulta: row, dados: row.medicamentos, campo: 'medicamentos', id: row._id, paciente: paciente.nome })
                      setOpen(true)
                    }}>
                      <Pageview/>
                    </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.exame === undefined || row.exame === null ) ? (
                <TextField
                  name="exame"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.exame.indexOf("lines") === -1) ? (
                <TextField
                  name="exame"
                  variant="outlined"
                  value={row.exame}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.exame}
                //   disabled
                // />
                <TextField
                  name="exame"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="Exame Físico"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({ titulo: row.titulo + ' - Exame Físico', consulta: row, dados: row.exame, campo: 'exame', id: row._id, paciente: paciente.nome })
                      setOpen(true)
                    }}>
                      <Pageview/>
                    </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.hipotese === undefined || row.hipotese === null) ? (
                <TextField
                  name="hipotese"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.hipotese.indexOf("lines") === -1) ? (
                <TextField
                  name="hipotese"
                  variant="outlined"
                  value={row.hipotese}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.hipotese}
                //   disabled
                // />
                <TextField
                  name="hipotese"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="Hipótese Diagnóstica"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({ titulo: row.titulo + ' - Hipótese Diagnóstica', consulta: row, dados: row.hipotese, campo: 'hipotese', id: row._id, paciente: paciente.nome })
                      setOpen(true)
                    }}>
                      <Pageview/>
                    </IconButton>
                  }
                />
              </GridListTile>
              <GridListTile>
              {(row.conduta === undefined || row.conduta === null) ? (
                <TextField
                  name="conduta"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (row.conduta.indexOf("lines") === -1) ? (
                <TextField
                  name="conduta"
                  variant="outlined"
                  value={row.conduta}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={row.conduta}
                //   disabled
                // />
                <TextField
                  name="conduta"
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="Conduta"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({ titulo: row.titulo + ' - Conduta', consulta: row, dados: row.conduta, campo: 'conduta', id: row._id, paciente: paciente.nome })
                      setOpen(true)
                    }}>
                      <Pageview/>
                    </IconButton>
                  }
                />
              </GridListTile>
            </GridList>
          </div>
        </Grid>
      </AccordionDetails>
    </Accordion>
    )
    ))}

    {/* Componente de edição da consulta */}
    <DialogEdit open={open} setOpen={setOpen} handleSalvar={handleSalvar} dados={dados} pen={pen} dimensions={dimensions} />

    {/* Snackbar confirmar deleção */}
    <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={deletar.open}
        message="Tem certeza que deseja excluir?"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => {setDeletar({open: false, id: '', campo: '', consulta: ''})}}>
              não
            </Button>
            <Button size="small" color="primary" onClick={handleDeletar}>
              sim
            </Button>
          </React.Fragment>
        }
      />
          
    </React.Fragment>
  )
}

export default withRouter(View);