import React from 'react'
import { Button, Dialog, DialogContent, AppBar, Toolbar, Typography, Icon, IconButton, Slide, TextField, Snackbar } from '@material-ui/core';
import { ArrowLeft, BorderColor, Close, Delete, Print, Search } from '@material-ui/icons';
import CanvasDraw from 'react-canvas-draw';
import PrintComponent from '../Print'
import useStyles from './style'
import { useReactToPrint } from 'react-to-print';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReceituarioEdit = ({open, setOpen, handleSalvar, dados, pen, dimensions, imprimir, setPesquisar}) => {
  const classes = useStyles();
  const editCanvas = React.useRef(null)
  const printRef = React.useRef(null)
  const [penTool, setPenTool] = React.useState({cor: '#000', pincel: 0.5, catenary: '#fff'});
  const [property] = React.useState({screen: true, width: false, fullWidth: false})
  const [viewPrint, setViewPrint] = React.useState(false)
  const [folha, setFolha] = React.useState()
  const [editar, setEditar] = React.useState(false)
  const [snack, setSnack] = React.useState(false)

  React.useEffect(() => {
    if (dados.dados !== '') {
      setEditar(true);
    }
    if (dados.dados.indexOf("lines") === -1) {
      setFolha(dimensions.height - 190)
    } else {
      let height = dados.dados.substring(dados.dados.length - 5)
      height = height.replace(":", "")
      height = height.replace("}", "")
      setFolha(parseInt(height))
    }
    // eslint-disable-next-line
  },[dados.dados])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleView = () => {
    if(editCanvas.current.value) {
      const keyboard = editCanvas.current.value 
      dados.dados = keyboard
      setViewPrint(true)
    } else if (editCanvas.current.value === undefined) {
      const pencil = editCanvas.current.getSaveData()
      dados.dados = pencil
      setViewPrint(true)
    } else {
      setViewPrint(true)
    }
  }

  const handleClose = () => {
    setEditar(true)
    setSnack(false)
    setOpen(false)
  }

  return (
    <>
    <Dialog fullWidth={property.fullWidth} maxWidth={property.width} fullScreen={property.screen} open={open} scroll='body' onClose={() => {}} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          {(viewPrint === false) ? (
            <>
              <IconButton edge="start" color="inherit" onClick={() => {editar ? setOpen(false) : setSnack(true) }} aria-label="close">
                <Close />
              </IconButton>
              <IconButton edge="start" color="inherit" onClick={handleView} aria-label="close">
                <Print />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton edge="start" color="inherit" onClick={() => {setViewPrint(false)}} aria-label="close">
                <ArrowLeft />
              </IconButton>
              <IconButton edge="start" color="inherit" onClick={handlePrint} aria-label="close">
                <Print />
              </IconButton>
            </>
          )}
          {setPesquisar ? (
          <IconButton edge="start" color="inherit" onClick={() => {setPesquisar(true)}}>
            <Search />
          </IconButton>  
          ) : null}
          <Typography variant="h6" className={classes.title}>
            {dados.titulo} - {dados.paciente}
          </Typography>
            {/* <Button autoFocus color="inherit" onClick={() => {handleSalvar(dados.campo, editCanvas)}}>
              Salvar
            </Button> */}
            {(editar) ? (
              <Button autoFocus color="inherit" onClick={() => {setEditar(false)}}>
                Editar
              </Button>
            ) : (
              <Button autoFocus color="inherit" onClick={() => {setEditar(true); handleSalvar(dados.campo, editCanvas)}}>
                Salvar
              </Button>
            )}
        </Toolbar>
      </AppBar>
      <DialogContent style={{maxWidth: dimensions.width, maxHeight: dimensions.height}}>
      {(viewPrint === false) ? (
        (dados.dados === '') ? (
          (pen) ? (
            <>
            <IconButton size="medium" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#FF0000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="default" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#fff', pincel: 10, catenary: '#000'});
              e.target.size = 'small';
            }}>
              <Icon className="fas fa-eraser" color="primary" />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              editCanvas.current.clear();
            }}>
              <Delete />
            </IconButton>
            <CanvasDraw
              ref={editCanvas}
              brushColor={penTool.cor}
              catenaryColor={penTool.catenary}
              brushRadius={penTool.pincel}
              canvasWidth={dimensions.width - 100}
              canvasHeight={folha}
              hideGrid={true}
              lazyRadius={1}
              style={{border: '1px solid #000'}}
              immediateLoading={true}
              saveData={dados.dados}
            />
            </>
          ) :(
            <>
            <TextField
              inputRef={editCanvas}
              variant="filled"
              rows={20}
              rowsMax={200}
              fullWidth
              multiline
            /> 
            </>
          )
        ) : (
        (dados.dados.indexOf("lines") === -1) ? (
            <TextField
              inputRef={editCanvas}
              variant="filled"
              defaultValue={dados.dados}
              rows={20}
              rowsMax={200}
              fullWidth
              multiline
              disabled={editar}
            /> 
          ) :(
            <>
            <IconButton size="medium" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#FF0000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="default" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#fff', pincel: 10, catenary: '#000'});
              e.target.size = 'small';
            }}>
              <Icon className="fas fa-eraser" color="primary" />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              editCanvas.current.clear();
            }}>
              <Delete />
            </IconButton>
            <CanvasDraw
              ref={editCanvas}
              brushColor={penTool.cor}
              catenaryColor={penTool.catenary}
              brushRadius={penTool.pincel}
              canvasWidth={dimensions.width - 100}
              canvasHeight={folha}
              hideGrid={true}
              lazyRadius={1}
              style={{border: '1px solid #000'}}
              immediateLoading={true}
              saveData={dados.dados}
              disabled={editar}
            />
            </>
        ))
        ) : (
          <PrintComponent imprimir={imprimir} dados={dados} printRef={printRef} />
        )} 
      </DialogContent>
    </Dialog>
    {/* Snackbar confirmar deleção */}
    <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={snack}
    message='Tem certeza que deseja sair sem salvar?'
    action={
      <React.Fragment>
        <Button color="secondary" size="small" onClick={() => { setSnack(false)}}>
          não
        </Button>
        <Button size="small" color="primary" onClick={ () => { handleClose() }}>
          sim
        </Button>
      </React.Fragment>
    }
    />
  </>
  )
}

export default ReceituarioEdit