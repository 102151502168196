import React from 'react'
import { Button, AppBar, Typography, Dialog, Icon, IconButton, Slide, TextField, Toolbar, Snackbar } from '@material-ui/core';
import { AddCircle, BorderColor, Close, Delete } from '@material-ui/icons';
import CanvasDraw from 'react-canvas-draw';
import useStyles from './style'
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogEdit = ({open, setOpen, handleSalvar, dados, pen, dimensions}) => {
  const classes = useStyles();
  const editCanvas = React.useRef(null)
  const [penTool, setPenTool] = React.useState({cor: '#000', pincel: 0.5, catenary: '#fff'});
  const [property] = React.useState({screen: true, width: false, fullWidth: false})
  const [folha, setFolha] = React.useState()
  const [editar, setEditar] = React.useState(false)
  const [snack, setSnack] = React.useState(false)

  useEffect(() => {
    if (dados.dados !== '') {
      setEditar(true);
    }
    if (dados.dados.indexOf("lines") === -1) {
      setFolha(dimensions.height - 190)
    } else {
      let height = dados.dados.substring(dados.dados.length - 5)
      height = height.replace(":", "")
      height = height.replace("}", "")
      setFolha(parseInt(height))
    }
    // eslint-disable-next-line
  },[dados.dados])

  const handleClose = () => {
    setEditar(true)
    setSnack(false)
    setOpen(false)
  }

  return (
    <>
    <Dialog fullWidth={property.fullWidth} maxWidth={property.width} fullScreen={property.screen} open={open} scroll='body' onClose={() => {}} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => {editar ? setOpen(false) : setSnack(true) }} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {dados.titulo} - {dados.paciente}
          </Typography>
          {(editar) ? (
            <Button autoFocus color="inherit" onClick={() => {setEditar(false)}}>
              Editar
            </Button>
          ) : (
            <Button autoFocus color="inherit" onClick={() => {setEditar(true); handleSalvar(dados.campo, editCanvas)}}>
              Salvar
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {/* <DialogContent style={{maxWidth: dimensions.width}}> */}
        {(dados.dados === '') ? (
          (pen) ? (
            <>
            <IconButton size="medium" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#FF0000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="default" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#fff', pincel: 10, catenary: '#000'});
              e.target.size = 'small';
            }}>
              <Icon className="fas fa-eraser" color="primary" />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              editCanvas.current.clear();
            }}>
              <Delete />
            </IconButton>
            <CanvasDraw
              ref={editCanvas}
              className={classes.canva}
              brushColor={penTool.cor}
              catenaryColor={penTool.catenary}
              brushRadius={penTool.pincel}
              canvasWidth={dimensions.width - 100}
              canvasHeight={folha}
              hideGrid={true}
              lazyRadius={1}
              style={{border: '1px solid #000'}}
              immediateLoading={true}
              saveData={dados.dados}
            />
            <div className={classes.pageBtn}>
              <IconButton size="medium" color='primary' aria-label="add" className={classes.fabAdd} onClick={(e) => {
                setFolha(folha + 600)
              }}>
                <AddCircle /> 
              </IconButton>
            </div>
            </>
          ) :(
            <>
            <TextField
              inputRef={editCanvas}
              variant="filled"
              rows={20}
              rowsMax={200}
              fullWidth
              multiline
            /> 
            </>
          )
        ) : (
        (dados.dados.indexOf("lines") === -1) ? (
            <TextField
              inputRef={editCanvas}
              variant="filled"
              defaultValue={dados.dados}
              rows={20}
              rowsMax={200}
              fullWidth
              multiline
              disabled={editar}
            /> 
          ) :(
            <>
            <IconButton size="medium" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#FF0000', pincel: 0.5, catenary: '#fff'});
              e.target.size = 'small';
            }}>
              <BorderColor />
            </IconButton>
            <IconButton size="medium" color="default" aria-label="add" className={classes.fab} onClick={(e) => {
              setPenTool({cor: '#fff', pincel: 10, catenary: '#000'});
              e.target.size = 'small';
            }}>
              <Icon className="fas fa-eraser" color="primary" />
            </IconButton>
            <IconButton size="medium" color="secondary" aria-label="add" className={classes.fabAdd} onClick={(e) => {
              editCanvas.current.clear();
            }}>
              <Delete />
            </IconButton>
            <CanvasDraw
              ref={editCanvas}
              className={classes.canva}
              brushColor={penTool.cor}
              catenaryColor={penTool.catenary}
              brushRadius={penTool.pincel}
              canvasWidth={dimensions.width - 100}
              canvasHeight={folha}
              hideGrid={true}
              lazyRadius={1}
              style={{border: '1px solid #000'}}
              immediateLoading={true}
              saveData={dados.dados}
              disabled={editar}
            />
            <div className={classes.pageBtn}>
              <IconButton size="medium" color='primary' aria-label="add" className={classes.fabAdd} onClick={(e) => {
                setFolha(folha + 600)
              }}>
                <AddCircle /> 
              </IconButton>
            </div>
            </>
        ))} 
      {/* </DialogContent> */}
    </Dialog>
    {/* Snackbar confirmar deleção */}
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={snack}
      message='Tem certeza que deseja sair sem salvar?'
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={() => { setSnack(false)}}>
            não
          </Button>
          <Button size="small" color="primary" onClick={ () => { handleClose() }}>
            sim
          </Button>
        </React.Fragment>
      }
    />
    </>
  )
}

export default DialogEdit