import React from 'react'
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Fab, 
  Grid, 
  TextField 
} from '@material-ui/core'
import { 
  AddPhotoAlternate, 
  Close 
} from '@material-ui/icons'
import useStyles from './style'

const Envio = ({paciente, titulo, data, setData, arquivo, setArquivo, handleSalvar}) => {
  const classes = useStyles()
  const [originalName, setOriginalName] = React.useState()
  const [imgSrc, setImgSrc] = React.useState(null)
  const [img, setImg] = React.useState(true)
  
  const handleChange = (event) => {
    const value = event.target.value;
    setData({ 
        ...data,
        originalName,
        img: imgSrc,
        [event.target.name]: value,
    });
  }

  const handleUpload = async (e) => { 
    const file = e.target.files[0]; 
    // if (e.target.files[0].name.indexOf('jpeg') !== -1) {
    //   alert('Não estamos prontos para receber esse tipo de arquivo')
    // } else {
      setOriginalName(file.name)
      const base64 = await convertBase64(file);
      changeBase64(base64);
      setImg(false)
    // }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) =>{
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onloadend = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const changeBase64 = (base64) => {
    setImgSrc(base64);
  };

  return (
    <React.Fragment>
      <Dialog
      fullWidth
      maxWidth='sm'
      open={arquivo}
      aria-labelledby="max-width-dialog-title"
      >
      <DialogTitle id="max-width-dialog-title">Salvar {titulo} - Paciente: {paciente}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              {img ? 
              <div>
              <input
                  accept="image/*, application/pdf"
                  id="contained-button-file"
                  type="file"
                  style={{display: "none"}}
                  onChange={e => handleUpload(e)}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" variant="extended" className={classes.button}>
                    <AddPhotoAlternate /> Clique aqui para escolher um arquivo do seu computador
                  </Fab>
                </label>
              </div>
              : 
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="tipo"
                  label={titulo === 'exame' ? 'Tipo de exame' : 'Número da ficha'}
                  onChange={handleChange}
                /> 
              </Grid>
              }
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => {
            setArquivo(false);
            setImgSrc(null);
            setImg(true)
          }}>
          <Close />
          Cancelar
        </Button>
        <Button color="primary" onClick={() => {
            setArquivo(false);
            setImgSrc(null);
            setImg(true);
            handleSalvar();
          }}>
          <Close />
          Salvar
        </Button>
        
      </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Envio;
