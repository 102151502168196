import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { People, Assignment, AttachFile, Today } from '@material-ui/icons';

export default function secretariaMenu() {
  return (
    <div>
      <ListItem button component={Link} to='/painel' >
        <ListItemIcon>
          <Today />
        </ListItemIcon>
        <ListItemText primary="Consultas" />
      </ListItem>
      <ListItem button component={Link} to='/secretaria/paciente' >
        <ListItemIcon>
          <People />
        </ListItemIcon>
        <ListItemText primary="Pacientes" />
      </ListItem>
      <ListItem button component={Link} to='/secretaria/ficha'>
        <ListItemIcon>
          <Assignment />
        </ListItemIcon>
        <ListItemText primary="Ficha" />
      </ListItem>
      <ListItem button component={Link} to='/secretaria/exame'>
        <ListItemIcon>
          <AttachFile />
        </ListItemIcon>
        <ListItemText primary="Exame" />
      </ListItem>
    </div>
  )
};