import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";
import { login } from "../../utils/auth";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import useStyles from './style'
import logo from '../../assets/logo.png';

const LogIn = (props) => {

  const classes = useStyles();

  const [email, setEmail] = useState();
  const [senha, setSenha] = useState();
  const [erro, setErro] = useState();

  const handleLogIn = async e => {
    e.preventDefault();
    
    if (!email || !senha) {
      setErro("Preencha e-mail e senha para continuar!");
    } else {
      try {
        const response = await api.post("/auth", { email, senha });
        login(response.data);
        
        props.history.push('/painel');
      } catch (error) {
        setErro("Houve um problema com o login, verifique suas credenciais.");
      }
    }
  };

  
  return(
    <Container componet="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <img src={logo} alt="Logo" width={140} height={50} />
        <form className={classes.form} onSubmit={handleLogIn} noValidate>
          {erro && <p>{erro}</p>}
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            id="email"
            label="Digite seu e-mail"
            name="email"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="senha"
            label="Digite sua senha"
            name="senha"
            type="password"
            onChange={e => setSenha(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="default"
            className={classes.submit}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="" variant="body2">
                Esqueci a senha?
              </Link>
            </Grid>
            <Grid item>
              <Link to="" variant="body2">
                {"Não tem uma conta? Cadastre-se!"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};


export default withRouter(LogIn);
