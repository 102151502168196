import axios from "axios";
import { getToken } from "../utils/auth"
import { store } from '../store' 
import { complete, loading } from "../store/actions/Api.actions"


const api = axios.create({
  baseURL: "https://api.prontudata.com.br"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  
  if (config.method === 'POST' || config.method === 'GET' || config.method === 'PUT')
    config.headers['Content-Type'] = 'application/json;charset=utf-8;';

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  store.dispatch(loading())

  return config;
});

api.interceptors.response.use(response => {
  store.dispatch(complete())
  
  return response
})

export default api;
