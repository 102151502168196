import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

import ListaPacientes from './reducers/ListaPacientes.reducer'
import Paciente from './reducers/Paciente.reducer'
import Consulta from './reducers/Consulta.reducer'
import Api from './reducers/Api.reducer'

const reducers = combineReducers({
  ListaPacientes,
  Paciente,
  Consulta,
  Api,
})

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const persistedReducer = persistReducer({
  key: 'reducers',
  storage
}, reducers)

export const store = createStore(
  persistedReducer, 
    compose(
      applyMiddleware(thunk)
  ))

export const persistedStore = persistStore(store)
