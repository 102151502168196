import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Delete } from '@material-ui/icons';
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import useStyles from './style';
import api from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { inserirPaciente, obterPacientes } from '../../store/actions/ListaPacientes.actions';
import { selecionarPaciente } from '../../store/actions/Paciente.actions';
import CadPaciente from '../CadPaciente';

const AgendaSecretaria = (props) => {
  const dispatch = useDispatch();
  const pacienteList = useSelector(state => state.ListaPacientes)
  const paciente = useSelector(state => state.Paciente)

  const classes = useStyles();

  const { medico } = JSON.parse(localStorage.getItem('Usuario'));

  const [selectMedico, setSelectMedico] = useState('');
  const [selectPaciente, setSelectPaciente] = useState('');
  const [data, setData] = useState('');
  const [agenda, setAgenda] = useState([]);
  const [load, setLoad] = useState(false);
  const [dialog, setDialog] = useState(false);
 
  useEffect(() => { /// Retorna os pacientes agendados
    if (load)
    api.get(`/agenda/${selectMedico}`).then(res => {
      setAgenda(res.data.agenda) 
    }).catch(err => {
      console.log(err)
      return err
    });

    return () => {
      setLoad(false);
    } 
    // eslint-disable-next-line 
  }, [load]);

  const handleMedicoSelect = async (event) => {
    dispatch(obterPacientes(event.target.value))
    setSelectMedico(event.target.value);
    setLoad(true);
  };

  const handlePacienteSelect = async (event) => {
    if(event.target.value === 'novo') {
      setDialog(true)
    } else {
      dispatch(selecionarPaciente(pacienteList, event.target.value))
      let tipo = ''
      if (paciente.consulta[0] !== undefined) {
        tipo = 'Retorno'
      } else {
        tipo = '1º Consulta'
      }
      setSelectPaciente(event.target.value);
      const agendaDados = {
        nome: paciente.nome,
        paciente_id: paciente._id,
        telefone: paciente.telefone,
        plano: paciente.plano,
        medico: medico,
        consulta: tipo,
      };
      agendamento(agendaDados);
    }
  };

  const agendamento = async (data) => {
    await api.post("/agenda", data).then(res => {
      setSelectPaciente('');
      setLoad(true);
    }).catch(err => {
      console.log(err.response);
    })
  }

  const handleSalvar = async () => {
    console.log(data)
    dispatch(inserirPaciente(data))
    setDialog(false);
    setData({})
    setSelectPaciente('');
    setLoad(true);
  }

  const handleExcluir = async (id) => {
    await api.delete(`/agenda/cancelar/${id}`).then(res => {
      setLoad(true);
    }).catch(err => {
      console.log(err.response);
    })
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Consultas do Dia
      </Typography>
      <FormControl variant='filled' className={classes.formControl}>
      <InputLabel>Selecione o Médico</InputLabel>
      <Select
        name="medico"
        fullWidth
        value={selectMedico}
        onChange={(event) => handleMedicoSelect(event)}
      >
        {(medico || []).map(item => (
          <MenuItem key={item._id} value={item._id}>{item.nome}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl variant='filled' className={classes.formControl}>
      <InputLabel>Selecione o Paciente</InputLabel>
      <Select
        name="paciente"
        fullWidth
        value={selectPaciente}
        onChange={(event) => handlePacienteSelect(event)}
      >
        <MenuItem value="novo">Cadastrar Paciente</MenuItem>
        <MenuItem disabled >------------------</MenuItem>
        {(pacienteList || []).map(item => (
          <MenuItem key={item._id} value={item._id}>{item.nome}</MenuItem>
        ))}
      </Select>
    </FormControl>

    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Paciente</TableCell>
            <TableCell align="right">Telefone</TableCell>
            <TableCell align="right">Plano</TableCell>
            <TableCell align="right">Tipo Consulta</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(agenda || []).map(row => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.nome}
              </TableCell>
              <TableCell align="right">{row.telefone}</TableCell>
              <TableCell align="right">{row.plano}</TableCell>
              <TableCell align="right">{row.consulta}</TableCell>
              <TableCell align="right">
              <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<Delete />}
                  onClick={() => {handleExcluir(row._id)}}
                >
                  Deletar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>


    {/* Cadastrar novo Paciente */}
    
    <CadPaciente medico={selectMedico} setData={setData} data={data} setDialog={setDialog} dialog={dialog} handleSalvar={handleSalvar}/>

    {/* <Snackbar open={erro} autoHideDuration={6000} onClose={() => {
      setAgendaDialog(false);
      setDados([]);
      setSelectPaciente('');
      setErro(false);
    }}>
      <Alert onClose={() => {
        setAgendaDialog(false);
        setDados([]);
        setSelectPaciente('');
        setErro(false);
      }} severity="error">
        Algum erro aconteceu! Verifique se o paciente já esta agendado, e tente novamente.
      </Alert>
    </Snackbar> */}
    
  </React.Fragment>
  )
}

export default withRouter(AgendaSecretaria);