import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import useStyles from './style';
import Sidebar from '../../../components/Sidebar'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Close, Delete, Edit, Save } from '@material-ui/icons';
import api from '../../../services/api';
import { useEffect } from 'react';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        ProntuDATA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Perfil = (props) => {
  const classes = useStyles();
  // Colocar dentreo de um useEffect

  const [dialog, setDialog] = useState(false)
  const [data, setData] = useState({});
  const [action, setAction] = useState('');
  const [medico, setMedico] = useState(JSON.parse(localStorage.getItem('Usuario')));
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (load)
      api.get(`/medico/exibir/${medico._id}`).then(res => {
        setMedico(res.data.medicos);
        localStorage.setItem('Usuario', JSON.stringify(res.data.medicos));
        setData('');
      }).catch(err => {
        console.log(err.response);
      }) 

      return () => {
        setLoad(false);
      } 
  // eslint-disable-next-line
  },[load]);
  
  const handleChangeSecretaria = (event) => {
    const value = event.target.value; 
    setData({
      ...data,
      [event.target.name]: value,
      role: 'secretaria',
    });
  };

  const handleChangeMedico = (event) => {
    const value = event.target.value; 
    setData({
      ...data,
      [event.target.name]: value,
      role: 'Médico',
    });
  };

  const handleSalvar = async (event) => {
    console.log(data);
    if (action === 'cadastrar') {
      await api.post(`/secretaria/buscar`, data).then(res => {
        // setDialog(false);
        if(res.data === 'cadastro') {
          cadastrarSecretaria();
        } else {
          atribuirSecretaria(res.data);
        }
      }).catch(err => {
        console.log(err.response);
      })
    } else if (action === 'atualizar') {
      await api.put(`/secretaria/atualizar/${data._id}`, data).then(res => {
        setDialog(false);
        setLoad(true);
      }).catch(err => {
        console.log(err.response);
      })
    }

    if (action === 'medico') {
      await api.put(`/medico/atualizar/`, data).then(res => {
        setData('');
        setLoad(true);
        // props.history.push('/painel')
      }).catch(err => {
        console.log(err.response);
      })
    }
  };

  const cadastrarSecretaria = async () => {
    await api.post(`/secretaria/cadastrar`, data).then(res =>{
      setDialog(false);
      setLoad(true);
    }).catch(err => {
      console.log(err.response)
    });
  };

  const atribuirSecretaria = async (id) => {
    await api.post(`/secretaria/atribuir/${id}`).then(res =>{
      setDialog(false);
      setLoad(true);
    }).catch(err => {
      console.log(err.response)
    });
  };

  const handleExcluir = async (id) => {
    await api.delete(`/secretaria/excluir/${id}`).then(res => {
      setLoad(true);
    }).catch(err => {
      console.log(err.response);
    })
  };

  // TODO 
  // - Configurar o Salvar, Editar e Excluir
  return (
    <div className={classes.root}>
      <Sidebar role={'medico'}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}> 
              <Typography variant="h6" gutterBottom>
                  Perfil 
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="nome"
                      name="nome"
                      label="Nome"
                      defaultValue={medico.nome}
                      autoComplete="given-name"
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      label="E-mail"
                      defaultValue={medico.email}
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      defaultValue={medico.telefone}
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      id="endereco"
                      name="endereco"
                      label="Endereço Completo"
                      defaultValue={medico.endereco}
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="especialidade"
                      name="especialidade"
                      label="Especialidade"
                      defaultValue={medico.especialidade}
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="crm"
                      name="crm"
                      label="CRM"
                      defaultValue={medico.crm}
                      onChange={handleChangeMedico}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAction('medico');
                      handleSalvar();
                    }}
                    >
                    Salvar
                    </Button>
                  </Grid>

                  
                  <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Secretárias 
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Secretária</TableCell>
                          <TableCell align="right">E-mail</TableCell>
                          <TableCell align="right">Telefone</TableCell>
                          <TableCell align="right">Data do Cadastro</TableCell>
                          <TableCell align="right">Excluir</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {medico.secretaria.map(row => (
                          <TableRow key={row._id}>
                            <TableCell component="th" scope="row">
                              {row.nome}
                            </TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                            <TableCell align="right">{row.telefone}</TableCell>
                            <TableCell align="right">{format(parseISO(row.createdAt), 'MM/dd/yyyy')}</TableCell>
                            <TableCell align="right">
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<Edit />}
                                onClick={() => {
                                  setAction('atualizar');
                                  setData({
                                    _id: row._id,
                                    nome: row.nome, 
                                    telefone: row.telefone,  
                                    email: row.email,  
                                    role: 'secretaria'
                                  });
                                  setDialog(true);
                                }}
                              >
                                Editar
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<Delete />}
                                onClick={() => {
                                  handleExcluir(row._id)
                                  }}
                              >
                                Deletar
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setAction('cadastrar');
                      setDialog(true);
                    }}
                    >
                    Cadastrar Secretária
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Dialog
          fullWidth
          maxWidth='lg'
          open={dialog}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="max-width-dialog-title">Cadastrar Secretária</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                      <TextField
                          required
                          name="nome"
                          label="Nome Secretária"
                          fullWidth
                          defaultValue={data.nome}
                          onChange={handleChangeSecretaria}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="telefone"
                          label="telefone"
                          required
                          fullWidth
                          defaultValue={data.telefone}
                          onChange={handleChangeSecretaria}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          name="email"
                          label="Email"
                          fullWidth
                          defaultValue={data.email}
                          onChange={handleChangeSecretaria}
                        />
                      </Grid>
                      {action === 'cadastrar' ?
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          name="senha"
                          label="Senha"
                          fullWidth
                          onChange={handleChangeSecretaria}
                        />
                      </Grid>
                      : null }
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setDialog(false)}} color="primary">
              <Close />
              Cancelar
            </Button>
            <Button onClick={handleSalvar} color="primary">
              <Save />
              Salvar
            </Button>
          </DialogActions>
        </Dialog>


          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Perfil);
