export const loading = () => {
  return {
    type: 'LOADING',
    payload: true
  }
}

export const complete = () => {
  return {
    type: 'COMPLETE',
    payload: false
  }
}