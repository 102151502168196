import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import useStyles from './style';
import Sidebar from '../../../components/Sidebar';
import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import Fichas from '../../../components/Ficha';
import { useDispatch, useSelector } from 'react-redux';
import { atribuirPaciente } from '../../../store/actions/Paciente.actions';
import { obterPacientes } from '../../../store/actions/ListaPacientes.actions';
import LoadPage from '../../../components/Load'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        ProntuDATA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FichaSecretaria() {
  const classes = useStyles();
  const dispatch = useDispatch()

  const load = useSelector(state => state.Api)

  const pacientes = useSelector(state => state.ListaPacientes)

  const { medico, nome, role } = JSON.parse(localStorage.getItem('Usuario'));
  const [select, setSelect] = useState('');
  const [open, setOpen] = useState(false)

  const handleSelect = (event) => {
    setSelect(event.target.value);
    dispatch(obterPacientes(event.target.value))
  };

  const handleClose = () => {
    setOpen(false)
    dispatch(obterPacientes(select))
  }

  pacientes.sort(function(a, b){
    if(a.nome < b.nome) { return -1; }
    if(a.nome > b.nome) { return 1; }
    return 0;
  })

  return (
    <div className={classes.root}>
      <LoadPage load={load} />
      <Sidebar title={nome} role={role} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography variant="h6" gutterBottom>
                Envio de Fichas Antigas
              </Typography>
              <FormControl variant='filled' className={classes.formControl}>
                <InputLabel id="medico-select-label">Selecione o Médico</InputLabel>
                <Select
                  id="medico-select"
                  value={select}
                  onChange={handleSelect}
                >
                  {medico.map(item => (
                    <MenuItem key={item._id} value={item._id}>{item.nome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!select ? ('') : (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Paciente</TableCell>
                        <TableCell align="center">Inserir Ficha</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pacientes.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row">
                            {row.nome}
                          </TableCell>
                          <TableCell align="center">
                          <Button 
                            aria-label="Capturar" 
                            onClick={() => {
                              dispatch(atribuirPaciente(row))
                              setOpen(true)
                            }}>
                            <AddCircle />
                          </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                )}
              </Paper>
            </Grid>
          </Grid>

          <Dialog maxWidth='md' fullWidth open={open} onClose={handleClose} TransitionComponent={Transition}>
              <DialogTitle>Fichas</DialogTitle>
              <Fichas />
          </Dialog>

          <Box pt={4}>
            <Copyright />
          </Box>

        </Container>
      </main>
    </div>
  );
}

