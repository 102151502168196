import { 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { ArrowForward, CloseOutlined, Delete } from '@material-ui/icons';
import useStyles from './style';

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import api from '../../services/api';
import { useSelector, useDispatch } from 'react-redux'
import { inserirPaciente, obterPacientes } from '../../store/actions/ListaPacientes.actions';
import { limparConsulta, novaConsulta } from '../../store/actions/Consulta.actions';
import { selecionarPaciente } from '../../store/actions/Paciente.actions'
import CadPaciente from '../CadPaciente';

const AgendaMedico = (props) => {
  const dispatch = useDispatch()
  const pacienteList = useSelector(state => state.ListaPacientes)
  const consulta = useSelector(state => state.Consulta)
  
  const classes = useStyles();

  const { _id } = JSON.parse(localStorage.getItem('Usuario'));

  const [agenda, setAgenda] = useState([]);
  const [loadAgenda, setLoadAgenda] = useState(true);
  const [selectPaciente, setSelectPaciente] = useState('');
  const [date] = React.useState(new Date().toLocaleDateString('pt-BR'))
  const [del, setDel] = React.useState({})
  const [snack , setSnack] = React.useState(false)
  const [snackDelete , setSnackDelete] = React.useState(false)
  const [data, setData] = React.useState({});
  const [dialog, setDialog] = useState(false);

  useEffect(() => { /// Retorna os pacientes agendados
    if (loadAgenda) {
      api.get(`/agenda/${_id}`).then(res => {
        setAgenda(res.data.agenda) 
      }).catch(err => {
        console.log(err)
        return err
      });

      dispatch(obterPacientes(_id))
    }
    return () => {
      setLoadAgenda(false);
    } 
    // eslint-disable-next-line
  }, [loadAgenda, agenda]);

  const handlePacienteSelect = (event) => {
    const paciente = event.target.value 
    if(paciente === 'novo') {
      setDialog(true)
    } else {
      let tipo = ''
      if (paciente.consulta[0] !== undefined) {
        tipo = 'Retorno'
      } else {
        tipo = '1º Consulta'
      }
      setSelectPaciente(event.target.value);
      const agendaDados = {
        nome: paciente.nome,
        paciente_id: paciente._id,
        telefone: paciente.telefone,
        plano: paciente.plano,
        medico: _id,
        consulta: tipo,
      };
      agendamento(agendaDados);
    }
  };

  const agendamento = async (data) => {
    await api.post("/agenda", data).then(res => {
      setSelectPaciente('');
      // setAgenda('');
      setLoadAgenda(true);
    }).catch(err => {
      console.log(err.response);
    })
  }

  const handleClick = (tipoConsulta, paciente, id_agenda) => {
    dispatch(selecionarPaciente(pacienteList, paciente))
    if (tipoConsulta === 'Retorno') {
      if (consulta.pacienteID === undefined) {
        dispatch(novaConsulta('Retorno', paciente))
        props.history.push({ 
          pathname: `/medico/paciente/retorno`,
          state: { agenda: id_agenda }, 
        })
      } else if(consulta.pacienteID === paciente) {
          // dispatch(novaConsulta('Retorno', paciente))
          props.history.push({ 
            pathname: `/medico/paciente/retorno`,
            state: { agenda: id_agenda }, 
          })
        } else {
          setSnack(true)
        }
    } else {
      if (consulta.pacienteID === undefined) {
        dispatch(novaConsulta('1º Consulta', paciente))
        props.history.push({
          pathname: `/medico/paciente/consulta`,
          state: { agenda: id_agenda },
        })
      } else if (consulta.pacienteID === paciente) {
        // dispatch(novaConsulta('1º Consulta', paciente))
        props.history.push({
          pathname: `/medico/paciente/consulta`,
          state: { agenda: id_agenda },
        })
      } else {
        setSnack(true)
      }
    }
  };

  const handleSalvar = async () => {
    dispatch(inserirPaciente(data))
    setDialog(false);
    setData({})
    setSelectPaciente('');
    setLoadAgenda(true);
    dispatch(obterPacientes(_id))
  };

  const handleExcluir = async () => {
    if (consulta.pacienteID === del.paciente) {
      dispatch(limparConsulta())
    }
    await api.delete(`/agenda/cancelar/${del.agenda}`).then(res => {
      setLoadAgenda(true);
    }).catch(err => {
      console.log(err.response);
    })
    setSnackDelete(false)
    setDel()
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Consultas - {date}
      </Typography>
      <FormControl variant='filled' className={classes.formControl}>
      <InputLabel>Selecione o Paciente</InputLabel>
      <Select
        name="paciente"
        fullWidth
        value={selectPaciente}
        onChange={(event) => handlePacienteSelect(event)}
      >
        <MenuItem value="novo">Cadastrar Paciente</MenuItem>
        <MenuItem disabled >------------------</MenuItem>
        {(pacienteList || []).map(item => (
          <MenuItem key={item._id} value={item}>{item.nome}</MenuItem>
        ))}
      </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Paciente</TableCell>
              <TableCell align="right">Telefone</TableCell>
              <TableCell align="right">Plano</TableCell>
              <TableCell align="right">Consulta</TableCell>
              <TableCell align="right">Excluir / Atender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {agenda && agenda.map(row => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.nome}
                </TableCell>
                <TableCell align="right">{row.telefone}</TableCell>
                <TableCell align="right">{row.plano}</TableCell>
                <TableCell align="right">{row.consulta}</TableCell>
                <TableCell align="right">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<Delete />}
                  onClick={() => {setSnackDelete(true); setDel({agenda: row._id, paciente: row.paciente_id})}}
                >
                  Deletar
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  startIcon={<ArrowForward />}
                  onClick={() => handleClick(row.consulta, row.paciente_id, row._id)}
                >
                  Atender
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Cadastrar novo Paciente */}
    
      <CadPaciente medico={_id} setData={setData} data={data} setDialog={setDialog} dialog={dialog} handleSalvar={handleSalvar}/>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={snack}
        message={`Você possui uma consulta em aberto, finalize a consulta do paciente anterior antes de continuar`}
        action={
          <React.Fragment>
            <IconButton color='inherit' size="small" onClick={() => {setSnack(false)}}>
              <CloseOutlined />
            </IconButton>
          </React.Fragment>
        }
      />

      {/* Snackbar confirmar deleção */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={snackDelete}
        message='Deseja realmente excluir esta consulta?'
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={() => {setSnackDelete(false)}}>
              não
            </Button>
            <Button size="small" color="primary" onClick={() => {handleExcluir()}}>
              sim
            </Button>
          </React.Fragment>
        }
      />

    </React.Fragment>
  );
}

export default withRouter(AgendaMedico);
