import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./utils/auth";

import LogIn from "./pages/LogIn";
import Painel from "./pages/Painel";

import ExameSecretaria from "./pages/Secretaria/Exame";
import FichaSecretaria from "./pages/Secretaria/Ficha";
import PacienteSecretaria from "./pages/Secretaria/Paciente";

import CBHPM from "./pages/Medico/CBHPM";
import CID from "./pages/Medico/CID10";
// import ExameMedico from "./pages/Medico/Exame";
import PacienteMedico from "./pages/Medico/Paciente";
import Visualizar from "./pages/Medico/Paciente/Visualizar";
import Consulta from "./pages/Medico/Paciente/Consulta";
import Retorno from "./pages/Medico/Paciente/Retorno";
import Perfil from "./pages/Medico/Perfil";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => 
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      ) 
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={LogIn} />
      <PrivateRoute path="/painel" component={Painel} />
      <PrivateRoute exact path="/secretaria/paciente" component={PacienteSecretaria} />
      <PrivateRoute path="/secretaria/exame" component={ExameSecretaria} />
      <PrivateRoute path="/secretaria/ficha" component={FichaSecretaria} />
      <PrivateRoute path="/medico/cbhpm" component={CBHPM} />
      <PrivateRoute path="/medico/cid" component={CID} />
      {/* <PrivateRoute path="/medico/exame" component={ExameMedico} /> */}
      <PrivateRoute exact path="/medico/paciente" component={PacienteMedico} />
      <PrivateRoute path="/medico/paciente/visualizar" component={Visualizar} />
      <PrivateRoute path="/medico/paciente/consulta" component={Consulta} />
      <PrivateRoute path="/medico/paciente/retorno" component={Retorno} />
      <PrivateRoute path="/medico/perfil" component={Perfil} />
      <Route path="*" component={() => <h1>Página não encontrada</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
