import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import { logout } from '../../utils/auth';

import useStyles from './style';
import medicoMenu from './medicoMenu';
import medicoMenuSecondary from './medicoMenuSecondary';
import secretariaMenu from './secretariaMenu';
import secretariaMenuSecondary from './secretariaMenuSecondary';
import { Menu, ChevronLeft, PowerSettingsNew } from '@material-ui/icons';
import { CssBaseline } from '@material-ui/core';
import  logo from '../../assets/logo.png';

const Sidebar = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    props.history.push('/');
  };

  const user = JSON.parse(localStorage.getItem('Usuario'));

  let mainMenu;
  let secondaryMenu;
  
  switch (props.role) {
    case 'medico': 
        mainMenu = medicoMenu()
        secondaryMenu = medicoMenuSecondary(user.secretaria)
      break;
    
    case 'secretaria':
        mainMenu = secretariaMenu()
        secondaryMenu = secretariaMenuSecondary(user.medico)
      break;

    default: 
      break;
  }
  
  return (
    <>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.title}>
            
          </Typography>
          <Typography variant="h6" color="inherit" noWrap >
            {user.nome}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}>
              <PowerSettingsNew />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logo} alt="Logo" width={140} height={50} /> 
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List>{mainMenu}</List>
        <Divider />
        <List>{secondaryMenu}</List>
      </Drawer>

    </>
  );
};

export default withRouter(Sidebar);

