import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  TableContainer, 
  Paper,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Close, Edit, Person, Save } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import api from '../../services/api';
import { useEffect } from 'react';
import LoadPage from '../Load'
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

// TODO
// - Antes de efetuar a exclusão do paciente, enviar ele para "lixeira" e avisar ao médico do paciente sobre a exclusão

const PacienteListSecretaria = (props) => {
  const classes = useStyles();

  const load = useSelector(state => state.Api)
  
  const { medico } = JSON.parse(localStorage.getItem('Usuario'));

  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState([]);
  const [acao, setAcao] = useState()
  const [select, setSelect] = useState('');
  const [loadPaciente, setLoadPaciente] = useState(false)
  const [paciente, setPaciente] = useState([]);
  
  useEffect(() => {
    if (loadPaciente)
    api.get(`/paciente/${select}`).then(res => {
      setPaciente(res.data.paciente) 
    }).catch(err => {
      return err
    });
    
    return () => {
      setLoadPaciente(false);
    } 
  }, [select, loadPaciente]);
  
  
  const handleSelect = (event) => {
    setSelect(event.target.value);
    setLoadPaciente(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setData({
      ...data,
      [event.target.name]: value,
      medico: select,
    });
  };

  const handleClick = (click, dados) => {
    if(select)
      if (click === 'cadastrar') {
        setDialog(true);
        setAcao(click)
      } else {
        setData(dados);
        setAcao(click)
        setDialog(true);
      }
  };

  const handleClose = () => {
    setDialog(false);
    setData([])
  };

  const handleSalvar = async () => {
    if (acao === 'atualizar') {
      await api.put(`/paciente/atualizar/${data._id}`, data).then(res => {
      setDialog(false);
      setData([])
      setLoadPaciente(true);
    }).catch(err => {
      console.log(err)
    })
    } else {
      await api.post("/paciente", data).then(res => {
        setDialog(false);
        setData([])
        setLoadPaciente(true);
      }).catch(err => {
        console.log(err.response);
      })
    }
  };

  return (
    <React.Fragment>
    <LoadPage load={load} />
    <Typography variant="h6" gutterBottom>
      Pacientes
    </Typography>
    <FormControl variant='filled' className={classes.formControl}>
      <InputLabel id="medico-select-label">Selecione o Médico</InputLabel>
      <Select
        id="medico-select"
        value={select}
        onChange={handleSelect}
      >
        {medico.map(item => (
          <MenuItem key={item._id} value={item._id}>{item.nome}</MenuItem>
        ))}
      </Select>
      <Button
        variant="contained"
        color="default"
        size="small"
        className={classes.cadButton}
        onClick={() => handleClick('cadastrar')}
        startIcon={<Person />}
      >
        Cadastrar Paciente
      </Button>
    </FormControl>
    
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Paciente</TableCell>
            <TableCell align="right">Endereço</TableCell>
            <TableCell align="right">Telefone</TableCell>
            <TableCell align="right">Plano</TableCell>
            <TableCell align="right">Última Consulta</TableCell>
            <TableCell align="right">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paciente.map(row => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.nome}
              </TableCell>
              <TableCell align="right">{row.endereco}</TableCell>
              <TableCell align="right">{row.telefone}</TableCell>
              <TableCell align="right">{row.plano}</TableCell>
              <TableCell align="right">{row.ultimaConsulta && format(parseISO(row.ultimaConsulta), 'dd/MM/yyyy')}</TableCell>
              <TableCell align="right">
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.actionBtn}
                  startIcon={<Edit />}
                  onClick={() => handleClick('atualizar', row)}
                >
                  Editar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    {/* Dialog para cadastro e atualização de novos Pacientes */}

    <Dialog
      fullWidth
      maxWidth='lg'
      open={dialog}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">Cadastrar Paciente</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
        <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="nome"
                      label="Nome"
                      fullWidth
                      autoComplete="given-name"
                      defaultValue={data.nome}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      name="nascimento"
                      label="Data de Nascimento"
                      fullWidth
                      autoComplete="given-name"
                      defaultValue={data.nascimento}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      name="email"
                      label="E-mail"
                      fullWidth
                      defaultValue={data.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask 
                      mask="(99) 99999-9999" 
                      onChange={handleChange}
                      name="celular" 
                      defaultValue={data.celular}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        label='Celular'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask 
                      mask="(99) 99999-9999" 
                      onChange={handleChange}
                      name="telefone" 
                      defaultValue={data.telefone}
                      required disableUnderline 
                      maskChar= {'_'}>

                      {() =>
                        <TextField
                        label='Telefone'
                        required
                        fullWidth
                      />}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="plano"
                      label="Plano de Saùde"
                      fullWidth
                      defaultValue={data.plano}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="endereco"
                      label="Endereço Completo"
                      fullWidth
                      defaultValue={data.endereco}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Close />
          Cancelar
        </Button>
        <Button onClick={handleSalvar} color="primary">
          <Save />
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
    
    </React.Fragment>
  );
}

export default withRouter(PacienteListSecretaria);