import React, { useEffect} from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { AppBar, Button, Dialog, DialogTitle, Fab, GridList, GridListTile, GridListTileBar, IconButton, Slide, TextField, Toolbar } from '@material-ui/core';
import { Assignment, Edit, Keyboard, Pageview, Save } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import useStyles from './style';
import { useState } from 'react';
import ViewConsulta from '../../../../components/Consulta/ViewConsulta'
import { loadCSS } from 'fg-loadcss';
import { useDispatch, useSelector } from 'react-redux'
import { novaConsulta, atualizarConsulta, limparConsulta } from '../../../../store/actions/Consulta.actions'
import { finalizarConsulta } from '../../../../store/actions/Paciente.actions';
import Prescricao from '../../../../components/Prescricao';
import Solicitacao from '../../../../components/Solicitacao';
import Exames from '../../../../components/Exame'
import Fichas from '../../../../components/Ficha'
import DialogEdit from '../../../../components/DialogEdit';
import api from '../../../../services/api';
import LoadPage from '../../../../components/Load'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        ProntuDATA
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Retorno = (props) => {
  const dispatch = useDispatch();
  const paciente = useSelector(state => state.Paciente);
  const consulta = useSelector(state => state.Consulta)
  const classes = useStyles();

  const load = useSelector(state => state.Api)
  
  const agenda = props.location.state.agenda;
  
  const [dados, setDados] = useState({titulo: '', dados: ''})
  const [pen, setPen] = useState(false);
  const [click, setClick] = useState({ pen: 'primary', key: 'default'});
  const [show, setShow] = useState({open: false})
  const [open, setOpen] = useState(false);
  const [salvo, setSalvo] = useState(false)
  const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight})

  const handleResize = (e) => {
    setDimensions({width: window.innerWidth, height: window.innerHeight});
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  },[])

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const handleSalvar = (campo, canvas) => {
    if(canvas.current.value) {
      const keyboard = canvas.current.value 
      dispatch(atualizarConsulta(campo, keyboard))
      setOpen(false)
    } else if (canvas.current.value === undefined) {
      const pencil = canvas.current.getSaveData()
      dispatch(atualizarConsulta(campo, pencil))
      setOpen(false)
    } else {
      setOpen(false)
    }
  }

  const handleFinalizarConsulta = async () => {
    await api.delete(`/agenda/cancelar/${agenda}`).then(res => {
      dispatch(finalizarConsulta(paciente, consulta, agenda))
      dispatch(limparConsulta())
      setSalvo(true)
      props.history.goBack()
    }).catch(err => {
      console.log(err.response);
    })
  }

  return (
    <div className={classes.root}>
      <LoadPage load={load} />
      <Prompt
        message={() => {
          if (salvo === false){
            return `Você tem certeza que deseja sair desta consulta sem salvar?`
          } 
        }}
      />
      <AppBar position="absolute">
        <Toolbar className={classes.toolbar}>
          <Button
            size='small'
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<Save />}
            onClick={handleFinalizarConsulta}
          >
            Finalizar Consulta
          </Button>
          <Button
            aria-controls="simple-menu" 
            aria-haspopup="true"
            size='small'
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<Save />}
            onClick={() => {setShow({open: true, click: 'consultas'})}}
          >
            Consultas Anteriores
          </Button>
          <Button
            size='small'
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<Assignment />}
            onClick={() => {setShow({open: true, click: 'fichas'})}}
          >
            Fichas Anteriores / Exames Anteriores
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>

          {/* Dados do paciente    */}

          <Grid item lg={12} sm={12}>
            <Typography variant="h6" gutterBottom>
                Paciente: {paciente.nome} - Nascimento: {paciente.nascimento}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Retorno
            </Typography>
          
            {/* Botões de escolha teclado e caneta */}

            <Fab color={click.key} size="small" aria-label="teclado" className={classes.fabKey} onClick={() => {
              setPen(false)
              setClick({
                pen: 'primary',
                key: 'default',
              })
            }}>
              <Keyboard />
            </Fab>      
            <Fab size="small" color={click.pen} aria-label="add" className={classes.fabPen} onClick={() => {
              setPen(true)
              setClick({
                pen: 'default',
                key: 'primary',
              })
            }}>
              <Edit/>
            </Fab>
          </Grid>

          {/* Dados do retorno atual */}
          <Grid item lg={12} sm={12}>
            {(consulta.titulo === 'Retorno') ? (
            <Button size="small" color="primary" variant="outlined" className={classes.button} onClick={() => {
              dispatch(novaConsulta('Retorno - Anamnese Completa'))
            }}>Anamnese Completa</Button>
            ) : (
            <Button size="small" color="primary" variant="outlined" className={classes.button} onClick={() => {
              dispatch(novaConsulta('Retorno'))
            }}>Retorno Simples</Button>
            )}
            {(consulta.titulo === 'Retorno') ? (
              
              // Retorno Simples 

              <GridList className={classes.gridList} cols={1}>
              <GridListTile>
              {(consulta.consulta === null || consulta.consulta === undefined) ? (
                <TextField
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
              (consulta.consulta.indexOf("lines") === -1) ? (
                <TextField
                  variant="outlined"
                  value={consulta.consulta}
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ) : (
                 /////////////////////////////////////////////////////////////////////////////////////// 
                //    Mudei para textfield pois o canvasdraw não permite deslizar usando os dedos.   //
                //    Pensar em outra forma de exibir os dados.                                      //
                ///////////////////////////////////////////////////////////////////////////////////////
                // <CanvasDraw
                //   catenaryColor="#fff"
                //   brushRadius={1}
                //   canvasWidth={gridWidth}
                //   canvasHeight={130}
                //   hideGrid={true}
                //   lazyRadius={1}
                //   style={{border: '1px solid #000'}}
                //   immediateLoading={true}
                //   saveData={consulta.consulta}
                //   disabled
                // />
                <TextField
                  variant="outlined"
                  rows={5}
                  rowsMax={20}
                  fullWidth
                  multiline
                  disabled
                /> 
              ))}
                <GridListTileBar
                    title="Retorno"
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  actionIcon={
                    <IconButton aria-label="star" color='primary' onClick={() => {
                      setDados({titulo: 'Retorno', dados: consulta.consulta, campo: 'consulta', tipo: 'Retorno', paciente: paciente.nome})
                      setOpen(true);
                    }}>
                      {(consulta.consulta === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                    </IconButton>
                  }
                />
              </GridListTile>
              </GridList>
            ) : (

              // Retorno Completo 
              
              <GridList className={classes.gridList} cols={1.5}>
                <GridListTile>
                {(consulta.queixa === null || consulta.queixa === undefined) ? (
                  <TextField
                    name="queixa"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.queixa.indexOf("lines") === -1) ? (
                  <TextField
                    name="queixa"
                    variant="outlined"
                    value={consulta.queixa}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.queixa}
                  //   disabled
                  // />
                  <TextField
                    name="queixa"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Queixa Principal"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({titulo: 'Anamnese Completa - Queixa Principal', dados: consulta.queixa, campo: 'queixa', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.queixa === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile> 
                {(consulta.doenca === null || consulta.doenca === undefined ) ? (
                  <TextField
                    name="doenca"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.doenca.indexOf("lines") === -1) ? (
                  <TextField
                    name="doenca"
                    variant="outlined"
                    value={consulta.doenca}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.doenca}
                  //   disabled
                  // />
                  <TextField
                    name="doenca"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="História da Doença Atual"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({titulo: 'Anamnese Completa - História da Doença Atual', dados: consulta.doenca, campo: 'doenca', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.doenca === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.historico === null || consulta.historico === undefined) ? (
                  <TextField
                    name="historico"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.historico.indexOf("lines") === -1) ? (
                  <TextField
                    name="historico"
                    variant="outlined"
                    value={consulta.historico}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.historico}
                  //   disabled
                  // />
                  <TextField
                    name="historico"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  />
                ))}
                  <GridListTileBar
                      title="História Patológica Pregressa"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: 'Anamnese Completa - História Patológica Pregressa', dados: consulta.historico, campo: 'historico', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.historico === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.medicamentos === null || consulta.medicamentos === undefined) ? (
                  <TextField
                    name="medicamentos"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.medicamentos.indexOf("lines") === -1) ? (
                  <TextField
                    name="medicamentos"
                    variant="outlined"
                    value={consulta.medicamentos}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.medicamentos}
                  //   disabled
                  // />
                  <TextField
                    name="medicmantos"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Medicamentos em Uso"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: 'Anamnese Completa - Medicamentos em Uso', dados: consulta.medicamentos, campo: 'medicamentos', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);
                      }}>
                        {(consulta.medicamentos === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.exame === null || consulta.exame === undefined) ? (
                  <TextField
                    name="exame"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.exame.indexOf("lines") === -1) ? (
                  <TextField
                    name="exame"
                    variant="outlined"
                    value={consulta.exame}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.exame}
                  //   disabled
                  // />
                  <TextField
                    name="exame"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Exame Físico"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: 'Anamnese Completa - Exame Físico', dados: consulta.exame, campo: 'exame', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.exame === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.hipotese === null || consulta.hipotese === undefined) ? (
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.hipotese.indexOf("lines") === -1) ? (
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    value={consulta.hipotese}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.hipotese}
                  //   disabled
                  // />
                  <TextField
                    name="hipotese"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Hipótese Diagnóstica"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: 'Anamnese Completa - Hipótese Diagnóstica', dados: consulta.hipotese, campo: 'hipotese', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.hipotese === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
                <GridListTile>
                {(consulta.conduta === null || consulta.conduta === undefined) ? (
                  <TextField
                    name="conduta"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                (consulta.conduta.indexOf("lines") === -1) ? (
                  <TextField
                    name="conduta"
                    variant="outlined"
                    value={consulta.conduta}
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ) : (
                  // <CanvasDraw
                  //   catenaryColor="#fff"
                  //   brushRadius={1}
                  //   canvasWidth={gridWidth}
                  //   canvasHeight={130}
                  //   hideGrid={true}
                  //   lazyRadius={1}
                  //   style={{border: '1px solid #000'}}
                  //   immediateLoading={true}
                  //   saveData={consulta.conduta}
                  //   disabled
                  // />
                  <TextField
                    name="conduta"
                    variant="outlined"
                    rows={5}
                    rowsMax={20}
                    fullWidth
                    multiline
                    disabled
                  /> 
                ))}
                  <GridListTileBar
                      title="Conduta"
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                    actionIcon={
                      <IconButton aria-label="star" color='primary' onClick={() => {
                        setDados({open: true, titulo: 'Anamnese Completa - Conduta', dados: consulta.conduta, campo: 'conduta', tipo: 'Anamnese Completa', paciente: paciente.nome})
                        setOpen(true);  
                      }}>
                        {(consulta.conduta === '') ? (
                          <Edit className={classes.title} /> 
                        ) : (
                          <Pageview className={classes.title} />  
                        )}
                      </IconButton>
                    }
                  />
                </GridListTile>
              </GridList>
            )}
          </Grid>

          <br />

          {/* Prescrições e Solicitações */}
          <Grid item sm={12} lg={12}>
            <Typography variant="h6" >Prescrições e Solicitações</Typography>
            <Prescricao pen={pen}/>
            <Solicitacao pen={pen}/>
          </Grid>
          </Grid>

          {/* Componente de edição da consulta */}
          <DialogEdit open={open} setOpen={setOpen} handleSalvar={handleSalvar} dados={dados} pen={pen} dimensions={dimensions} />
          

          {/* Dialog das consultas arquivadas, das fichas e exames   */}
          <Dialog maxWidth='md' fullWidth open={show.open} onClose={() => {setShow({open: false})}} TransitionComponent={Transition}>
            {(show.click === 'fichas') ? (
            <>
              <DialogTitle>Arquivos</DialogTitle>
              <Fichas />
              <Exames />
            </>
            ) : (
              <>
                <DialogTitle>Consultas Anteriores</DialogTitle>
                <ViewConsulta pen={pen} dimensions={dimensions} />
              </>
            )}
          </Dialog>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default withRouter(Retorno);
