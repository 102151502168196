import api from "../../services/api"

// Atribui um paciente para o state
export const atribuirPaciente = (paciente) => {
  return {
    type: 'ATRIBUIR_PACIENTE',
    payload: paciente
  } 
}
// Seleciona um paciente da lista de pacientes para o state paciente
export const selecionarPaciente = (pacienteList, id) => {
  return {
    type: 'SELECIONAR_PACIENTE_PACIENTELIST',
    payload: {
      list: pacienteList,
      id: id
    }
  }
}

export const editarPaciente = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/atualizar/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)
    
  dispatch({
    type: 'EDITAR_PACIENTE',
    payload: retornoAPI
  })
}

export const editarConsulta = (consulta, campo, dados, id) => async (dispatch) => {
  const updateConsulta = {
    ...consulta,
    [campo]: dados,
  }
  const retornoAPI = await api
    .put(`/paciente/consulta/editar/${id}`, updateConsulta)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'EDITAR_CONSULTA',
    payload: retornoAPI
  })
}

export const deletarConsulta = (paciente_id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/consulta/deletar/${paciente_id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'DELETAR_CONSULTA',
    payload: retornoAPI,
  })
}

export const inserirPrescricao = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .post(`/paciente/prescricao/${id}`, {texto: dados})
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'INSERIR_PRESCRICAO',
    payload: retornoAPI,
  })
}

export const editarPrescricao = (id, texto) => async(dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/prescricao/editar/${id}`, {texto: texto})
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'EDITAR_PRESCRICAO',
    payload: retornoAPI,
  })
}

export const deletarPrescricao = (paciente_id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/prescricao/deletar/${paciente_id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'DELETAR_PRESCRICAO',
    payload: retornoAPI,
  })
}

export const inserirSolicitacao = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .post(`/paciente/solicitacao/${id}`, {texto: dados})
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'INSERIR_SOLICITACAO',
    payload: retornoAPI,
  })
}

export const editarSolicitacao = (id, texto) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/solicitacao/editar/${id}`, {texto: texto})
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'EDITAR_SOLICITACAO',
    payload: retornoAPI,
  })
}

export const deletarSolicitacao = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/solicitacao/deletar/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'DELETAR_SOLICITACAO',
    payload: retornoAPI,
  })
}

export const inserirFicha = (id, dados) => async (dispatch) => {
  console.log(dados)
  const retornoAPI = await api
    .post(`/paciente/ficha/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'INSERIR_FICHA',
    payload: retornoAPI,
  })
}

export const deletarFicha = (id, dados) => async (dispatch) => {
  const retornoAPI = await api  
    .put(`/paciente/ficha/deletar/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'DELETAR_FICHA',
    payload: retornoAPI,
  })
}

export const inserirExame = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .post(`/paciente/exame/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'INSERIR_EXAME',
    payload: retornoAPI,
  })
}

export const deletarExame = (id, dados) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/exame/deletar/${id}`, dados)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'DELETAR_EXAME',
    payload: retornoAPI,
  })
}

export const finalizarConsulta = (paciente, dadosConsulta, agenda) => async (dispatch) => {
  paciente.consulta.push(dadosConsulta)
  
  const retornoAPI = await api
    .put(`/paciente/atualizar/${paciente._id}`, paciente)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'FINALIZAR_CONSULTA',
    payload: retornoAPI
  }) 
}

export const sairVisualizacao = (paciente) => async (dispatch) => {
  const retornoAPI = await api
    .put(`/paciente/atualizar/${paciente._id}`, paciente)
    .then(res => res.data.paciente)
    .catch(err => err.message)

  dispatch({
    type: 'SAIR_VISUALIZACAO',
    payload: retornoAPI
  }) 
}