export default function (state = [], action) {
  switch (action.type) {
    case 'NOVA_CONSULTA':
      return action.payload

    case 'ATUALIZAR_CONSULTA':
      return {
        ...state,
        [action.payload.campo]: action.payload.dados
      } 
    
    case 'LIMPAR_CONSULTA':
      return []
    
    default: 
      return state
  }
}